const FaqDataVps = {
    faqItems: [
        {
            id:1,
            title:"What is DhakaCloud VPS hosting?",
            ans:"DhakaCloud VPS hosting offers better scalability and performance for your application by leveraging cloud technology and VPS, which comes with an easy 1-click installation & instant deployment.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"How many Operating System is there?",
            ans:"A wide variety of popular operating systems are available including CentOS and Ubuntu.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"Where is your cloud server located??",
            ans:"The cloud server is located at Bangladesh, Singapore, Frankfurt and Mumbai,",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"What is the delivery time?",
            ans:"After successful payment, your server will be deployed automatically. If instant setup fails for any reason, the setup will be done within 24 hours with manual intervention.",
            iconUrl:"q1.png"
        },
        {
            id:5,
            title:"What kind of customer support should I expect?",
            ans:"DhakaCloud is providing 24x7 support having its skilled Technical resources.",
            iconUrl:"q1.png"
    },
    ],

}


export default FaqDataVps
