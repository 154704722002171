import React, { Component, useEffect } from 'react'
import HeroTwo from './Hero/HeroTwo';
import DomainOffer from './Features/DomainOffer';
import DomainData from './Features/DomainData';
import DomainBox from './Features/DomainBox';
import ServiceTwo from './Services/ServiceTwo';
import ServiceData from './Services/ServiceData';
import PriceTwo from './Price/PriceTwo';
import Map from './Map';
import WhyUs from './WhyUs';
import FaqData from './Faq/FaqData';
import FaqTwo from './Faq/FaqTwo';
import Team from './Team';
import TeamData from './Data/TeamData';
import TestimonialData from './Testimonial/TestimonialData';
import Testimonial from './Testimonial/Testimonial';
import ContactOne from './Contact/ContactOne';
import CpanelHosting from './Services/cPanelHosting';
import ServiceThree from './Services/ServiceThree';
import VPS from './Services/vps';
import DS from './Services/DedicatedServer';
import SSL from './Services/SSLCertificate';
import PriceThree from './Price/PriceThree';
import PriceOne from './Price/PriceOne';
import FaqDataCpanel from './Faq/FaqDataCpanel';
import HeroTwoCommon from './Hero/HeroTwoCommon';
import HeroCpanel from './Hero/HeroCpanel';
import PriceCpanel from './Price/PriceCpanel';

const PanelPage = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

        return (
          <React.Fragment>
                 <HeroCpanel/>
                {/* <WhyUs/> */}
                {/* <ServiceTwo ServiceData={ServiceData} /> */}
                {/* <CpanelHosting /> */}
                <PriceOne />
                <PriceCpanel />
                {/* <PriceTwo/> */}
                {/* <PriceThree /> */}
                <FaqTwo FaqData={FaqDataCpanel} />
                <ServiceThree ServiceData={ServiceData} />
                {/* <VPS />
                <DS /> */}
                {/* <SSL /> */}
                {/* <Map/> */}
                {/* <DomainOffer/> */}
                {/* <Team TeamData={TeamData} />
                <Testimonial TestimonialData={TestimonialData} /> */}
                {/* <ContactOne/> */}
          </React.Fragment>
        )
    }

export default PanelPage
