import React, { Component } from 'react'
import Slider from 'react-slick';
import { Link } from 'react-router-dom';


// class ContentBlockAboutUs extends Component {
//     render() {

const ContentBlockAboutUs = () => {
    return (
        <section>
            {/* <img src={require('../assets/img/aboutus/SECTION01.png')} alt="DhakaCloud" width="100%" height="100%" /> */}
            <div style={{ backgroundColor: '#552289' }}>
                <div className='container py-5'>
                    <div className='row'>
                        <div className='col-md-6 px-5'>
                            <h1 style={{ color: 'white', marginBottom: '20px', marginTop: '60px', fontSize: '60px' }}>Who we are</h1>
                            <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>Welcome to Dhakacloud, your trusted partner for all your online infrastructure needs. With years of experience in the industry, we specialize in providing Virtual Private Servers (VPS), Domain purchasing, Web Hosting, SSL Certificate, Managed DNS Service, S3 Storage, Email Services etc.<br /><br />
                                At Dhakacloud, we understand the importance of a reliable and secure online presence. Our goal is to empower businesses and individuals alike by offering top-notch infrastructure solutions that ensure optimal performance and peace of mind.<br /><br />
                                What sets us apart is our commitment to customer satisfaction. We take pride in delivering personalized services tailored to the unique requirements of each client. Whether you're a small business owner, a growing startup, or a seasoned enterprise, we have the expertise and flexibility to meet your needs. <br /><br />
                                Our team consists of skilled professionals who are passionate about what they do. We continuously stay updated with the latest industry trends and technological advancements to offer cutting-edge solutions. From the moment you engage with us, you can expect friendly and knowledgeable support that is ready to assist you every step of the way.<br /><br />
                                At Dhakacloud, we believe in transparency and integrity. We strive to build long-lasting relationships with our clients by providing transparent pricing, honest advice, and reliable services. We understand that your success is our success, and we are dedicated to helping you thrive in the online world.<br /><br />
                                At Dhakacloud, we take pride in our robust backend infrastructure, carefully designed to deliver exceptional performance, reliability, and security for your online needs. </p>
                        </div>
                        <div className='col-md-6 px-5'>
                            <img src={require('../assets/img/aboutus/SECTION02.png')} alt="DhakaCloud" style={{ position: 'relative', top: '13%' }} />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <img src={require('../assets/img/aboutus/SECTION03.png')} alt="DhakaCloud" />
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: '20%', padding: '0 46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Data Center</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    We have strategically selected state-of-the-art data centers located in geographically diverse regions. These data centers are equipped with advanced facilities to ensure the
                                    highest level of uptime and data protection. Each facility features redundant power supplies, climate control systems, fire suppression, and 24/7 security measures to safeguard
                                    your valuable data.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 px-5'>
                            <div style={{ marginTop: '10%', paddingRight: '46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Servers</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    Our servers are built with industry-leading hardware to provide unparalleled performance and stability. We utilize enterprise-grade equipment, including powerful processors,
                                    ample RAM, and fast storage solutions, to ensure speedy data processing and seamless operations. With a focus on scalability, our server infrastructure can easily accommodate
                                    your growing needs without compromising performance.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img src={require('../assets/img/aboutus/SECTION04.png')} alt="DhakaCloud" />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <img src={require('../assets/img/aboutus/SECTION05.png')} alt="DhakaCloud" />
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: '20%', padding: '0 46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Network Connectivity</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    We understand that fast and reliable network connectivity is crucial for delivering an exceptional user experience. That's why we have established partnerships with top-tier
                                    network providers- IPT, Global & Local IX, ISPs, NTTNs to ensure excellent connectivity across our infrastructure. By leveraging redundant network links and advanced routing technologies, we optimize data transfer
                                    speeds and minimize latency, allowing your online presence to thrive.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <div style={{ marginTop: '30%', paddingRight: '46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Network Backbone</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    Our network backbone is built on a highly resilient and redundant architecture, designed to handle heavy workloads and maintain uninterrupted connectivity. Multiple network links
                                    are in place to eliminate single points of failure and provide seamless failover capabilities. With our robust network backbone, we deliver exceptional network performance,
                                    reliability, and scalability.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img src={require('../assets/img/aboutus/SECTION06.png')} alt="DhakaCloud" />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <img src={require('../assets/img/aboutus/SECTION07.png')} alt="DhakaCloud" />
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: '15%', padding: '0 46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Security</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    Protecting your data is our top priority. We employ stringent security measures to safeguard your information against unauthorized access, data breaches, and other potential
                                    threats. Our infrastructure includes Next-Gen Firewall, IPS/IDS, WAF, DDoS Protection, Regular Security Audits, and Proactive Monitoring to ensure a secure hosting environment.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <div style={{ marginTop: '15%', paddingRight: '46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Mission</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    Our mission is to empower businesses and individuals with reliable, secure, and scalable cloud hosting solutions that accelerate their digital transformation. We are committed to delivering exceptional performance, outstanding customer support, and innovative technologies that enable our clients to focus on their core objectives while we handle their hosting needs.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <img src={require('../assets/img/aboutus/SECTION08.png')} alt="DhakaCloud" style={{ width: '80%', margin: '0 auto', display: 'block' }} />
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-6 px-5'>
                            <img src={require('../assets/img/aboutus/SECTION09.png')} alt="DhakaCloud" />
                        </div>
                        <div className='col-md-6'>
                            <div style={{ marginTop: '10%', padding: '0 46px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Vision</h2>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    Our vision is to be the leading cloud hosting service provider, driving the future of digital infrastructure. We strive to redefine the hosting experience by consistently delivering cutting-edge solutions that meet the evolving demands of our clients. We aim to build lasting partnerships, foster innovation, and empower businesses worldwide to thrive in the digital era by leveraging the power of cloud computing.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='row mt-5'>
                        <div className='col-md-12'>

                            <div style={{ paddingLeft: '30px' }}>
                                <h2 style={{ color: 'white', marginBottom: '20px' }}>Core Values</h2>
                                <h4 style={{ color: 'white' }}>Reliability</h4>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    We prioritize reliability as the foundation of our services, ensuring that our clients' websites and applications are always accessible and perform optimally.
                                </p>
                                <br />
                                <h4 style={{ color: 'white' }}>Security</h4>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    We uphold the highest standards of data security and privacy, implementing robust measures to protect our clients' valuable information and maintain their trust.
                                </p>
                                <br />
                                <h4 style={{ color: 'white' }}>Scalability</h4>
                                <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                    We provide flexible and scalable hosting solutions that can effortlessly accommodate the growth of our clients' businesses, allowing them to scale resources as needed.
                                </p>
                                <br />
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div style={{ paddingRight: '46px' }}>
                                            <h4 style={{ color: 'white' }}>Customer-Centric Approach</h4>
                                            <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                                We are dedicated to delivering exceptional customer experiences by providing responsive and knowledgeable support, tailored solutions, and proactive guidance throughout our clients' hosting journey.
                                            </p>
                                            <br />
                                            <h4 style={{ color: 'white' }}>Innovation</h4>
                                            <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                                We foster a culture of innovation, constantly exploring emerging technologies and industry trends to offer our clients the most advanced and efficient hosting solutions available.
                                            </p>
                                            <br />
                                            <h4 style={{ color: 'white' }}>Sustainability</h4>
                                            <p className='text-justify' style={{ color: 'white', lineHeight: '1.5', wordBreak: 'break-all' }}>
                                                We are committed to reducing our environmental impact and promoting sustainability in our operations, employing energy-efficient infrastructure and supporting renewable energy sources.
                                            </p>
                                        </div>
                                    </div>
                                    <div className='col-md-6'>
                                        <img src={require('../assets/img/aboutus/SECTION10.png')} alt="DhakaCloud" />
                                    </div>
                                </div>
                                <p style={{ color: 'white', lineHeight: '1.5', marginTop: '80px', textAlign: 'center' }}>
                                    By adhering to these values, we strive to be the trusted partner that businesses rely on to navigate the complexities of cloud hosting, enabling them to unlock their full potential and achieve remarkable success in the digital landscape.
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </section>
    )
}

export default ContentBlockAboutUs
