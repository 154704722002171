import React, { Component } from 'react'
import Navbar from './Navbar';

// class Header extends Component {
//     render() {
const Header = () => {
        return (
            <div className="bg-blue-100 h-20 w-full centered">
            <Navbar />
            </div>
        )
    }
// }

export default Header
