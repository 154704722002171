import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import HomeOne from './components/HomeOne';
import HomeTwo from './components/HomeTwo';
import HomeThree from './components/HomeThree';
import AOS from 'aos';
import Header from './components/Header';
import Client from './components/Client';
import Cta from './components/Cta';
import Footer from './components/Footer';
import FooterData from './components/FooterWidgets/FooterData';

import './App.css';
import FeaturePage from './components/FeaturePage';
import DomainPage from './components/DomainPage';
import HostingPage from './components/HostingPage';
import ServicePage from './components/ServicePage';
import ContactPage from './components/ContactPage';
import AboutUsPage from './components/AboutUsPage';
import PanelPage from './components/cPanelPage';
import VpsPage from './components/VpsPage';
import DsPage from './components/DsPage';
import SslPage from './components/SSLPage';
import EmailHostingPage from './components/ManageDNSPage';
import CoLocationHostingPage from './components/CoLocationHostingPage';
import TermsCondition from './components/T&C';
import PrivacyPolicy from './components/PrivacyPolicy';
import RefundPolicy from './components/RefundPolicy';
import ManageDNSPage from './components/ManageDNSPage';
import StoragePage from './components/StoragePage';


function App() {
  AOS.init();

  useEffect(() => {
    window.fbAsyncInit = function() {
        window.FB.init({
            appId: '1672649539875689', // Replace with your actual App ID
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v11.0'
        });
        window.FB.Event.subscribe('customerchat.load', function() {
            console.log('Customer chat loaded');
        });
    };

    (function(d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
}, []);


  return (
    <div className="main-wrapper">

      <Header />
      <Routes>
        <Route>

          {/* <Route path='/HomeOne' element={<HomeOne />} /> */}
          <Route path='/' element={<HomeTwo />} />
          <Route path='/feature' element={<FeaturePage />} />
          {/* <Route path='/HomeThree' element={<HomeThree />} /> */}
          <Route path='/domain' element={<DomainPage />} />
          <Route path='/hosting' element={<HostingPage />} />
          <Route path='/service' element={<ServicePage />} />
          <Route path='/contact' element={<ContactPage />} />
          <Route path='/about-us' element={<AboutUsPage />} />

          <Route path='/shared-hosting' element={<PanelPage />} />
          <Route path='/virtual-private-hosting' element={<VpsPage />} />
          <Route path='/dedicatedserver' element={<DsPage />} />

          <Route path='/sslcertificate' element={<SslPage />} />
          <Route path='/managedns' element={<ManageDNSPage />} />
          <Route path='/storage' element={<StoragePage />} />
          <Route path='/colocation' element={<CoLocationHostingPage />} />
          <Route path='/termsandconditions' element={<TermsCondition />} />
          <Route path='/privacypolicy' element={<PrivacyPolicy />} />
          <Route path='/refundpolicy' element={<RefundPolicy />} />

          {/* <Route path='/service' element={<ServiceTwo ServiceData={ServiceData} />} /> */}


        </Route>
      </Routes>
      {/* <Client/> */}
      {/* <Cta CtaTitle="Start building your website today!" CtaDec="We make register, hosting and managing domain for yourself or others easy and affordable, because the internet needs people" CtaBtnText="Get Started" ctaLink=".#" /> */}
      <Footer FooterData={FooterData} />
    </div>
  );

}

export default App;
