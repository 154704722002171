const TestimonialData = {
    reviewItem: [
        {
            id:1,
            imgUrl:"1",
            cName:"Engineers",
            cPosition:"System",
            companyName:"Race",
            reviewText:"We are ready to help you"
        },
        {
            id:2,
            imgUrl:"2",
            cName:"Engineers Core",
            cPosition:"Core",
            companyName:"Earth",
            reviewText:"All certified engineers"
        },
        {
            id:3,
            imgUrl:"3",
            cName:"Engineers NOC",
            cPosition:"DhakaCloud",
            companyName:"Colo",
            reviewText:"Hi All"
        },
        // {
        //     id:4,
        //     imgUrl:"4",
        //     cName:"Support Engineers",
        //     cPosition:"Developer",
        //     companyName:"Orbit",
        //     reviewText:"We are here."
        // }
    ],

}


export default TestimonialData
