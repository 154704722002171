import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom'
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import './assets/css/bootstrap.min.css';
import './assets/main.css';
import './assets/css/slick-theme.css';
import './assets/css/slick.css';
import './assets/css/aos.css';
import './assets/css/fontawesome.min.css';
// import 'bootstrap/dist/js/bootstrap.min.js';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import reportWebVitals from "./reportWebVitals";


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//     //<React.Fragment>
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>,
//     //</React.Fragment>,
//     document.getElementById('root')
//     );
// reportWebVitals();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// ReactDOM.render(<App />, document.getElementById('root'));
// serviceWorker.unregister();

