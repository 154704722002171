import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'


export class PriceOne extends Component {
    render() {

        return (
            // <section className="price-wrap section-padding-cprice pt-0" id="price">
            <section className="price-wrap section-padding-cprice" id="price">
                <div className="container">

                    <SectionTitle Title="Super Fast & Secure cPanel With Domain" Titlep="Choose the best cpanel hosting plan from you business with SSL Certificates." />

                    <div className="row">
                        <div className="col-12 col-lg-12">
                            <div className="tab-content">
                                <div id="month" className="tab-pane in active">
                                    <div className="row">
                                        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                            <div className="single-price-table" data-aos="fade-up" data-aos-delay={200} data-aos-duration={800}>
                                                <div className="package-name text-center">
                                                    <h3 style={{marginBottom: '5px'}}>Startup</h3>
                                                    <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>FREE.COM Domain</span>
                                                </div>
                                                <div className="package-features-price">
                                                    <div className="price text-center">
                                                        <span>1700 BDT/Annually</span>

                                                    </div>
                                                    <ul>
                                                        <li><i className="fal fa-check-circle" />5GB NVMe Space</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Bandwidth</li>
                                                        <li><i className="fal fa-check-circle" />5 FTP Accounts</li>
                                                        <li><i className="fal fa-check-circle" />15 E-mail Address</li>
                                                        <li><i className="fal fa-check-circle" />5 Mailing Lists</li>
                                                        <li><i className="fal fa-check-circle" />15 MySQL Databases</li>
                                                        <li><i className="fal fa-check-circle" />20 Sub Domain</li>
                                                        <li><i className="fal fa-check-circle" />3 Parked Domains</li>
                                                        <li><i className="fal fa-check-circle" />1 Addon Domain</li>
                                                        <li><i className="fal fa-check-circle" />Control Panel &amp; FTP</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                    </ul>
                                                </div>
                                                <div className="package-btn">
                                                    <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=1">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                            <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                                <div className="package-name text-center">
                                                    <h3 style={{marginBottom: '5px'}}>Standard</h3>
                                                    <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>FREE.COM Domain</span>
                                                </div>
                                                <div className="package-features-price">
                                                    <div className="price text-center">
                                                        <span>2100 BDT/Annually</span>
                                                    </div>
                                                    <ul>
                                                        <li><i className="fal fa-check-circle" />8GB NVMe Space</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Bandwidth</li>
                                                        <li><i className="fal fa-check-circle" />7 FTP Accounts</li>
                                                        <li><i className="fal fa-check-circle" />25 E-mail Address</li>
                                                        <li><i className="fal fa-check-circle" />7 Mailing Lists</li>
                                                        <li><i className="fal fa-check-circle" />20 MySQL Databases</li>
                                                        <li><i className="fal fa-check-circle" />25 Sub Domain</li>
                                                        <li><i className="fal fa-check-circle" />5 Parked Domains</li>
                                                        <li><i className="fal fa-check-circle" />3 Addon Domain</li>
                                                        <li><i className="fal fa-check-circle" />Control Panel &amp; FTP</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                    </ul>
                                                </div>
                                                <div className="package-btn">
                                                    <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=2">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                            <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                                <div className="package-name text-center">
                                                    <h3 style={{marginBottom: '5px'}}>Popular</h3>
                                                    <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>FREE.COM Domain</span>
                                                </div>
                                                <div className="package-features-price">
                                                    <div className="price text-center">
                                                        <span>2750 BDT/Annually</span>
                                                    </div>
                                                    <ul>
                                                        <li><i className="fal fa-check-circle" />10GB NVMe Space</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Bandwidth</li>
                                                        <li><i className="fal fa-check-circle" />12 FTP Accounts</li>
                                                        <li><i className="fal fa-check-circle" />35 E-mail Address</li>
                                                        <li><i className="fal fa-check-circle" />10 Mailing Lists</li>
                                                        <li><i className="fal fa-check-circle" />25 MySQL Databases</li>
                                                        <li><i className="fal fa-check-circle" />50 Sub Domain</li>
                                                        <li><i className="fal fa-check-circle" />7 Parked Domains</li>
                                                        <li><i className="fal fa-check-circle" />5 Addon Domain</li>
                                                        <li><i className="fal fa-check-circle" />Control Panel &amp; FTP</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                    </ul>
                                                </div>
                                                <div className="package-btn">
                                                    <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=3">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                            <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                                <div className="package-name text-center">
                                                    <h3 style={{marginBottom: '5px'}}>Premium</h3>
                                                    <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>FREE.COM Domain</span>
                                                </div>
                                                <div className="package-features-price">
                                                    <div className="price text-center">
                                                        <span>3550 BDT/Annually</span>
                                                    </div>
                                                    <ul>
                                                        <li><i className="fal fa-check-circle" />15GB NVMe Space</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Bandwidth</li>
                                                        <li><i className="fal fa-check-circle" />20 FTP Accounts</li>
                                                        <li><i className="fal fa-check-circle" />50 E-mail Address</li>
                                                        <li><i className="fal fa-check-circle" />15 Mailing Lists</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited MySQL DB</li>
                                                        <li><i className="fal fa-check-circle" />100 Sub Domain</li>
                                                        <li><i className="fal fa-check-circle" />10 Parked Domains</li>
                                                        <li><i className="fal fa-check-circle" />7 Addon Domain</li>
                                                        <li><i className="fal fa-check-circle" />Control Panel &amp; FTP</li>
                                                        <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                    </ul>
                                                </div>
                                                <div className="package-btn">
                                                    <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=4">Order Now</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default PriceOne
