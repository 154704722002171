import React, { Component } from 'react'
import SectionTitle from './SectionTitle'



const ContentBlockDNS = () =>{
        return (
            <section className="content-block-wrap section-padding-sslsup pb-130">
                <div className="container">
                    
                <SectionTitle Title="Why do you need Premium DNS?"  Titlep="When users type domain names into the URL bar in their browser, DNS servers are responsible for translating those domain names to numeric IP addresses, leading them to the correct website." />
                {/* <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=43" className="btn btn-success" target="_blank">Buy PremiumDNS</a> */}
                
              
                    <div className="row flex-row-reverse">
                        <div className="col-xl-5 col-lg-6 offset-xl-1 col-sm-12 col-12">
                            <div className="content-block pt-60" data-aos="fade-left" data-aos-duration={1200}>
                                <span className="top-title">Premium DNS</span>
                                <h2>always on duty</h2>
                                <p>Your Premium DNS service will always be on duty, easily able to monitor and intuitively secure DNS hosting on your behalf. Get DNS Security Extensions (DNSSEC) support to combat DNS attacks, super-fast DNS speeds with global Anycast servers handling the busiest of websites and your queries, and your website 100% available — for one hugely affordable price–per–year. Forget about lost connections and data leaks. Advanced DNS security with Premium DNS has you covered.

Remember, Premium DNS can be used with any domain, registered anywhere, and delivers the fastest DNS hosting speeds, together with airtight DNS security.</p>
                                {/* <a href=".#" className="theme-btn">Get the service</a> */}
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-sm-12 col-12">
                            <div className="content-featured-img pt-60" data-aos="fade-right" data-aos-duration={1200}>
                                <img src={require ("../assets/img/ssl-certificate.gif") } alt="klaud" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

export default ContentBlockDNS
