const ServiceDataVps = {
    serviceBoxItem: [
        {
            id:1,
            title:"Easy to Setup VPS",
            text:"User friendly web interface with simple specified instuction to setup VPS.",
            imgUrl:"setup.png"
        },
        {
            id:2,
            title:"Fastest Performance",
            text:"Rapid data processing and retrival with blazing fast server grade CPU, RAM & SSD.",
            imgUrl:"fast.png"
        },
        {
            id:3,
            title:"Privacy Protected",
            text:"DDoS & firewall protected server. Every VPS users have firewall control also.",
            imgUrl:"privacy.png"
        },
        {
            id:4,
            title:"Threat Protected",
            text:"Firewall protected network and infrastructure with industry grade firewall appliances.",
            imgUrl:"privacy.png"
        },
        {
            id:5,
            title:"Automated Backup",
            text:"Scheduled default backup policy applicable for every VPS hosting service.",
            imgUrl:"backup.png"
        },
        {
            id:6,
            title:"Control Panel",
            text:"Web access portal for contorolling significant tasks for managing the VPS.",
            imgUrl:"ssl.png"
        },
        {
            id:7,
            title:"Resource Modification",
            text:"Available server resources to modify as per needs and requirements.",
            imgUrl:"database.png"
        },
        {
            id:8,
            title:"Smart Billing",
            text:"Automated modern billing system helps to improve clarity in billing calculation.",
            imgUrl:"boost.png"
        },
        {
            id:9,
            title:"Multiple Network",
            text:"Top-tier network operators are available to ensure excellent best route across locally & globally",
            imgUrl:"network.png"
        }
    ],

}


export default ServiceDataVps
