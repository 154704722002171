import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'
import Table from '../TableComponent/Table';



class Storage extends Component {

    state = {
        data: [
            {
                packagename: "Startup",
                price: "৳ 89 BDT/Monthly",
                price2: "89 BDT/Monthly",
                storage: "30 GB",
                bw: "Unlimited",
                bdix: "Unlimited",
                user: 1,
                // gallery: "Yes",
                // syncshare: "Yes",
                // documents: "Yes",
                // apps: "Yes",
                // period: "Minimum 1 Month",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=61" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=61',
                color: 'linear-gradient(140deg, #27b88dd9 0%, #22dd73d4 100%)'
            },
            {
                packagename: "Standard",
                price: "৳ 149 BDT/Monthly",
                price2: "149 BDT/Monthly",
                storage: "50 GB",
                bw: "Unlimited",
                bdix: "Unlimited",
                user: 1,
                // gallery: "Yes",
                // syncshare: "Yes",
                // documents: "Yes",
                // apps: "Yes",
                // period: "Minimum 1 Month",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=62" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=62',
                color: 'linear-gradient(120deg, #a02aafd9 0%, #441687db 100%)'
            },
            {
                packagename: "Popular",
                price: "৳ 299 BDT/Monthly",
                price2: "299 BDT/Monthly",
                storage: "100 GB",
                bw: "Unlimited",
                bdix: "Unlimited",
                user: 1,
                // gallery: "Yes",
                // syncshare: "Yes",
                // documents: "Yes",
                // apps: "Yes",
                // period: "Minimum 1 Month",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=63" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=63',
                color: 'linear-gradient(120deg, #e84769 0%, #9d1d97 100%)'
            },
            {
                packagename: "Premium",
                price: "৳ 799 BDT/Monthly",
                price2: "799 BDT/Monthly",
                storage: "300 GB",
                bw: "Unlimited",
                bdix: "Unlimited",
                user: 1,
                // gallery: "Yes",
                // syncshare: "Yes",
                // documents: "Yes",
                // apps: "Yes",
                // period: "Minimum 1 Month",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=64" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=64',
                color: 'linear-gradient(120deg, #f5592ac9 0%, #f80155d4 100%)'
            },

        ]
        
    }

    columns = [
        { label: "Package", path: "packagename", content: (item, path) => <td> <span className='rounded-pill text-white py-1' style={{  background: '#42247f',width:'85%', display: 'inline-block'}}>{item[path]}</span> </td> },
        { label: "Price", path: "price", content: (item, path) => <td> {item[path]} </td> },
        { label: "Cloud Storage", path: "storage", content: (item, path) => <td> {item[path]} </td> },
        { label: "Bandwidth", path: "bw", content: (item, path) => <td> {item[path]} </td> },
        { label: "BDIX Connectivity", path: "bdix", content: (item, path) => <td> {item[path]} </td> },
        // { label: "User", path: "user", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Gallery", path: "gallery", content: (item, path) => <td> {item[path]} </td> },
        // { label: "File sync and share", path: "syncshare", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Documents", path: "documents", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Desktop & mobile apps", path: "apps", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Contract Period", path: "period", content: (item, path) => <td> {item[path]} </td> },
        { label: "Action", path: "action", content: (item, path) => <td> {item[path]} </td> }
    ];


    render() {
        return (
            // <section className="services-wrap service-two section-padding-vps pt-0" id="vps">
            <section className="services-wrap service-two section-padding-vps" id="vps">
                <div className="container">

                    <SectionTitle Title="Choose your storage plan" Titlep="Secure Cloud Storage accessible from anywhere with your complete control." />

                    <div className="row">

                        {/* <h1>WindowsVirtual Private Server</h1> */}
                    </div>
                    <Table
                        items={this.state.data}
                        columns={this.columns}
                    />

                    <div className="row" id='alternative-for-table'>
                        <div className="col-12 col-lg-12">
                            <div className="tab-content">
                                <div id="month" className="tab-pane in active">

                                    <div className="row"  >
                                        {this.state.data.map((item, index) => {
                                            return (
                                                <div className="col-xl-3 col-md-6 col-sm-12 col-12" key={index}>
                                                    <div className="single-price-table" data-aos="fade-up" data-aos-delay={200} data-aos-duration={800}>
                                                        <div className="package-name text-center">
                                                            <h3>{item.packagename}</h3>
                                                            {/*<span>{item.vcpu}</span>*/}
                                                        </div>
                                                        <div className="package-features-price" style={{ background: `${item.color}` }}>
                                                            <div className="price text-center">
                                                                <span>{item.price2}</span>

                                                            </div>
                                                            <ul>
                                                                <li><i className="fal fa-check-circle" />{item.storage}</li>
                                                                <li><i className="fal fa-check-circle" />{'BDIX '}{item.bdix}</li>
                                                                <li><i className="fal fa-check-circle" />{'B/W '}{item.bw}</li>
                                                                {/*<li><i className="fal fa-check-circle" />{'User '}{item.user}</li>*/}
                                                            </ul>
                                                        </div>
                                                        <div className="package-btn">
                                                            <a target="_blank" href={item.href} style={{ background: `${item.color}` }}>Order Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <SectionTitle Title="The latest hosting server technology"  Titlep="VPS. Lorem Ipsum has been the industry's standard dummy text ever since" /> */}
                </div>
            </section>
        )
    }
}

export default Storage
