import React, { Component } from 'react'
import SectionTitle from './SectionTitle';
import headphoneImage from '../assets/img/headphone.svg';
import cloudImage from '../assets/img/cloud.svg';
import dhostImage from '../assets/img/dhost.svg';
import networkImage from '../assets/img/network.svg';


const WhyUs = () => {

    // const titleStyle = {
    //   fontSize: '18px',
    //   fontWeight: 'bold',
    //   color: 'blue',
    //   // add more style properties as needed
    // };

    // const titleParagraphStyle = {
    //   fontSize: '14px',
    //   color: 'black',
    //   // add more style properties as needed
    // };
    //         return (

    //             <div className="col-xl-4 col-lg-4 col-sm-12">
    //                 <div className="sepcail-featured-img">
    //                     <img src={require ("../assets/img/mainserver1.png") } alt="" />
    //                 </div>
    //             </div>  
    // )


    return (
        <section className="why-us-wrap section-padding" id="whyus">
            <div className="container">

                <SectionTitle Title="Why You choose Dhaka Cloud" Titlep="Dhaka Cloud Infrastructure is Fully Managed by Multiple Tier-III Data Centers across the World. Skilled Professionals are Available in 24/7 mode ensuring Service Quality, Reliability and Availability" />

                {/* <div>
                    <h1 style={titleStyle}>Why You choose Dhaka Cloud</h1>
                    <p style={titleParagraphStyle}>
                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
                    </p>
                </div> */}

                {/* <div className="row mt-40">
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="special-fetaured-list right-icon">
                            <div className="single-special-feature">
                                <div className="special-icon">
                                    <img src={headphoneImage} alt="" />
                                   
                                </div>
                                <div className="sf-text">
                                    <span>Online Support</span>
                                    <p>24/7 Real-Time Online Support and Modern Ticketing System is Available</p>
                                </div>
                            </div>
                            <div className="single-special-feature">
                                <div className="special-icon s2">
                                    <img src={cloudImage} alt="" />
                                </div>
                                <div className="sf-text">
                                    <span>Cloud Hosting</span>
                                    <p>Lightning-Fast Cloud Hosting to Keep Your Website Running Relentlessly, Securly and Smoothly </p>
                                </div>
                            </div>
                            <div className="single-special-feature">
                                <div className="special-icon s3">
                                    <img src={dhostImage} alt="" />
                                </div>
                                <div className="sf-text">
                                    <span>Dedicated hosting</span>
                                    <p>Harness the Full Potential of Dedicated Resources for Your Website</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="sepcail-featured-img">
                            <img src={require("../assets/img/mainserver.png")} alt="" />
                        </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-sm-12">
                        <div className="special-fetaured-list left-icon">
                            <div className="single-special-feature">
                                <div className="special-icon s4">
                                    <img src={require("../assets/img/lock.png")} alt="" />
                                </div>
                                <div className="sf-text">
                                    <span>Privacy Protected</span>
                                    <p>Safeguard Your Online Privacy and Critical Data with Our Privacy-First Service</p>
                                </div>
                            </div>
                            <div className="single-special-feature">
                                <div className="special-icon s5">
                                    <img src={networkImage} alt="" />
                                </div>
                                <div className="sf-text">
                                    <span>Multiple Network</span>
                                    <p>Experience Uninterrupted Network Connectivity With All Local & Popular International IX</p>
                                </div>
                            </div>
                            <div className="single-special-feature">
                                <div className="special-icon s6">
                                    <img src={require("../assets/img/fast.png")} alt="" />
                                </div>
                                <div className="sf-text">
                                    <span>Fast performance</span>
                                    <p>SSD and NVMe Optimised Storage, DDR4 RAM, Scalable Processor with 25Gbps+ Network Interface</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='row'>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                            <div style={{ padding: '10px 60px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Dedicated<br /> Hosting</h3>
                                <p style={{ lineHeight: '1.4' }}>Harness the Full Potential of Dedicated Resources for Your Website</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                            <div style={{ padding: '10px 60px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Fast<br /> Performance</h3>
                                <p style={{ lineHeight: '1.4' }}>SSD and NVMe Optimised Storage, DDR4 RAM, Scalable Processor with 25Gbps+ Network Interface</p>
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                            <div style={{ padding: '10px 32px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Privacy<br />Protected</h3>
                                <p style={{ lineHeight: '1.4' }}>Safeguard Your Online Privacy and Your Valueable Critical Data with Our Privacy-First Service</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='row'>
                    <img src={require("../assets/img/whyuschose/6REASONSLICE.png")} style={{
                        width: '90%',
                        margin: '0 auto'
                    }} alt="whyuschose" />
                </div>
                <div className='row'>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>

                            <div style={{ padding: '10px 60px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Cloud<br /> Hosting</h3>
                                <p style={{ lineHeight: '1.4' }}>Lightning-Fast Cloud Hosting to Keep Your Website Running Relentlessly, Securly and Smoothly</p>
                            </div>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>

                            <div style={{ padding: '10px 60px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Multiple<br /> Network</h3>
                                <p style={{ lineHeight: '1.4' }}>Experience Uninterrupted Network Connectivity With All Local & Popular International IX</p>
                            </div>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                        </div>
                    </div>
                    <div className='col-md-4'>
                        <div style={{ textAlign: 'center' }}>

                            <div style={{ padding: '10px 60px' }}>
                                <h3 style={{ fontSize: '30px', lineHeight: '1.2', marginBottom: '10px' }}>Online<br /> Support</h3>
                                <p style={{ lineHeight: '1.4' }}>24/7 Real-Time Online Support and Modern Ticketing System is Available</p>
                            </div>
                            <div>
                                <img src={require("../assets/img/whyuschose/TICKSIGN.png")} width="50px" height="50px" alt="tick" />
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}

export default WhyUs
