import React, { Component } from 'react';
import Slider from 'react-slick';
import SectionTitle from '../SectionTitle'

class Testimonial extends Component {
  render() {
    var bg = require('../../assets/img/bgshape.svg');

    var TestimonialData = this.props.TestimonialData;

    const settings = {
      slidesToShow: 3,
      dots: true,
      slidesToScroll: 1,
      arrows: false,
      autoplay: true,
      centerPadding: '0px',
      autoplaySpeed: 3000,
      centerMode: true,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: true,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }

      ]
    };

    return (
      <section className="testimonail-wrap section-padding" id="testimonial">
        <div className="testimonial-bg" style={{ backgroundImage: "url(" + bg + ")" }}>
        </div>
        <div className="container">

          <SectionTitle Title="Our Skilled Resources" Titlep="We have more then 100 skilled engineers who will support you 24/7" />

          {/* <div className="row">
            <div className="col-lg-12 col-12">
              <Slider className="testimonial-list" {...settings}>
                            {
                                TestimonialData.reviewItem.map(item=> {
                                    return(
                                        <div className="single-testimonial" key={item.id}>
                                            <div className="client-img">
                                                <img src={require (`../../assets/img/client/${item.imgUrl}.jpg`) } alt="klaud" />
                                            </div>
                                            <div className="client-info">
                                                <h4>{item.cName}</h4>
                                                <p>{item.cPosition} at <span>{item.companyName}</span></p>
                                            </div>
                                            <p>{item.reviewText}</p>
                                        </div>
                                    )
                                })
                            }
                            </Slider>
            </div>
          </div> */}

          <div className="row d-flex justify-content-center" style={{ marginTop: '90px' }}>
            <div className="col-md-3">
              <div class="card" style={{ backgroundColor: 'transparent', border: '1px solid transparent', height: '90%' }}>
                <img src={require("../../assets/img/skills/Asset7.png")} height="100%" alt="tick" />
                <div class="card-img-overlay text-center">
                  <img src={require("../../assets/img/skills/Asset4.png")} style={{ width: '120px', height: '120px', marginTop: '-92px' }} alt="tick" />
                  <div className='mt-3'>
                    <h1 class="card-title" style={{ color: '#521A75', fontSize: '50px' }}>15+</h1>
                    <h3 class="card-text" style={{ color: '#396DAA', lineHeight: '1.1' }}>Innovative Idea<br /> Generators</h3>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div class="card" style={{ backgroundColor: 'transparent', border: '1px solid transparent', height: '90%' }}>
                <img src={require("../../assets/img/skills/Asset8.png")} height="100%" alt="tick" />
                <div class="card-img-overlay text-center">
                  <img src={require("../../assets/img/skills/Asset5.png")} style={{ width: '120px', height: '120px', marginTop: '-92px' }} alt="tick" />
                  <div className='mt-3'>
                    <h1 class="card-title" style={{ color: '#521A75', fontSize: '50px' }}>20+</h1>
                    <h3 class="card-text" style={{ color: '#396DAA', lineHeight: '1.1' }}>Skilled Certified<br /> Resources</h3>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-md-3">
              <div class="card" style={{ backgroundColor: 'transparent', border: '1px solid transparent', height: '90%' }}>
                <img src={require("../../assets/img/skills/Asset9.png")} height="100%" alt="tick" />
                <div class="card-img-overlay text-center">
                  <img src={require("../../assets/img/skills/Asset6.png")} style={{ width: '120px', height: '120px', marginTop: '-92px' }} alt="tick" />
                  <div className='mt-3'>
                    <h1 class="card-title" style={{ color: '#521A75', fontSize: '50px' }}>15Y+</h1>
                    <h3 class="card-text" style={{ color: '#396DAA', lineHeight: '1.1' }}>Industry<br /> Experts</h3>
                  </div>

                </div>
              </div>
            </div>
          </div>

        </div>
      </section>
    )
  }
}

export default Testimonial
