const ServiceDataSSL = {
    serviceBoxItem: [
        {
            id:1,
            title:"Guaranteed Security",
            text:"SSL Security Certificates secure the connection between your website and your customer’s browser. Once your SSL certificate has been issued and installed, you will enjoy uninterrupted online security.",
            // imgUrl:"ssl-guaranteed-security.jpg"
            imgUrl:"fast.png"
        },
        {
            id:2,
            title:"Boost Credibility",
            text:"SSL Certificates are necessity for online businesses and websites. Online customers are aware of the difference between a secure and unsecure website. Having Comodo SSL your website will ensure you never lose out on traffic and sales. ",
            // imgUrl:"ssl-boost-credibility.jpg"
            imgUrl:"ssl.png"
        },
        {
            id:3,
            title:"Strongest Encryption",
            text:"Comodo SSL Certificates use SHA2 & ECC 128/256 bit encryption which is an industry benchmark. If you have a certificate that has a SHA1 certificate, we recommend update your certificate to SHA2.",
            // imgUrl:"ssl-strongest-encryption.jpg"
            imgUrl:"privacy.png"
        },
        {
            id:4,
            title:"Address bar Visibility",
            // text:"The issuance of an Extended Validation or EV certificate provides your website with your company name on a green bar and padlock in the web browser where your website is displayed. The address of the website changes from HTTP to HTTPS which also signifies that the website is secured. Your customers will feel more confident making online payments on your website.",
            text:"Extended Validation certificate provides your website with your company name on a green bar and padlock in the web browser. HTTP to HTTPS signifies that the website is secured and your customers will be confident making payments.",
            // imgUrl:"ssl-address-bar-visibility.jpg"
            imgUrl:"backup.png"
        },
        {
            id:5,
            title:"Improved SEO",
            // text:"Websites that are not digitally certified have fewer website traffic and fewer conversions as customers lack the confidence to browse through the site. Further, browsers such as Google Chrome notify website visitors with a warning if a site has no valid SSL certificate. Search engines such as Google penalize websites identified to be not secured. ",
            text:"Customers will not be confident to browse any site if that is not digitally certified. Browsers such as Google Chrome always notify the visitors with a warning if that site has no valid SSL certificate and penalize the website as well.",
            // imgUrl:"ssl-Improved-seo.jpg"
            imgUrl:"ssl.png"
        },
        {
            id:6,
            title:"Extended Validation",
            // text:"In addition to domain validation, Comodo SSL Security Certificates provide an extensive validation of your business as well. Comodo will verify if your business registration is legal and your domain name’s WhoIs information is authentic, ensuring only the most trusted companies display the green bar and padlock on their website-which is the ultimate assurance of web security.",
            text:"In addition to domain validation, Comodo SSL Security Certificates provide an extensive validation of your business as well. Comodo will verify if your business registration is legal and your domain name’s WhoIs information is authentic.",
            // imgUrl:"ssl-extended-validation.jpg"
            imgUrl:"database.png"
        },
        {
            id:7,
            title:"TrustLogo Site Seal",
            text:"Comodo SSL Security Certificates allow you to place a TrustSeal logo on your website giving your customers further visual assurance of the security and credibility of your website much better.",
            // imgUrl:"ssl-trustLogo-site-seal.jpg"
            imgUrl:"boost.png"
        },
        {
            id:8,
            title:"Secure all Sub-domains",
            // text:"Secure all your website subdomains with one plan i.e the Wildcard Plan. The Wildcard plans cover your primary domain (www.domain.com/domain.com) as well as all other subdomains such as mail.domain.com, login.domain.com, etc. ",
            text:"Wildcard Plan is the one stop solution to secure your website and subdomains. It covers your primary domain (www.domain.com/domain.com) as well as other subdomains such as mail.domain.com etc.",
            // imgUrl:"ssl-secure-all-sub-domains.jpg"
            imgUrl:"network.png"
        },
        {
            id:9,
            title:"Secure all Sub-domains",
            text:"Wildcard Plan is the one stop solution to secure your website and subdomains. It covers your primary domain (www.domain.com/domain.com) as well as other subdomains such as mail.domain.com etc.",
            // imgUrl:"ssl-secure-all-sub-domains.jpg"
            imgUrl:"fast.png"
        }
    ],

}


export default ServiceDataSSL
