import React, { useEffect, useState, useRef } from "react";
import { Link as PageLink, NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { BiSolidServer } from "react-icons/bi";
import { BsServer } from "react-icons/bs";
import { AiFillSafetyCertificate } from "react-icons/ai";
import { FaGlobe } from "react-icons/fa";
import $ from "jquery";

const Navbar = () => {
    const [hosting, setHosting] = useState(false);
    const [services, setServices] = useState(false);
    const [mobileMenu, setMobileMenu] = useState(false);
    const menuRef = useRef(null);

    const isMobileMenu = () => {
        setMobileMenu(!mobileMenu);
    };

    const closeMobileMenu = () => {
        setMobileMenu(false);
    };

    useEffect(() => {
        const handleScroll = () => {
            const Width = window.innerWidth;

            if (
                window.scrollY > 100 ||
                document.documentElement.scrollTop > 100
            ) {
                if (Width > 767) {
                    document.querySelector("header").classList.add("sticky");
                }
            } else {
                document.querySelector("header").classList.remove("sticky");
            }
        };
        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    useEffect(() => {
        // Function to close mobile menu when clicking outside of it
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target)) {
                setMobileMenu(false);
            }
        };

        // Add event listener
        document.addEventListener("mousedown", handleClickOutside);

        // Clean up event listener on unmount
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <header className="header-wraper transparent-menu">
            <div className="main-menu">
                <div className="container">
                    <div className="row">
                        <div className="col-md-3 col-lg-2 d-flex col-6 col-sm-4">
                            <PageLink className="navbar-brand logo" to="/">
                                <img
                                    src={require("../assets/img/logo.png")}
                                    alt="Cloud"
                                />
                            </PageLink>
                        </div>
                        <div className="col-xl-7 col-lg-7 offset-xl-1 col-md-7 col-7 pr-0 d-none d-lg-block text-lg-right">
                            <nav className="menu-1">
                                <ul className="menu-items">
                                    <li>
                                        <NavLink
                                            to="/"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            Home
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/domain"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            Domain
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/virtual-private-hosting"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            VPS
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/shared-hosting"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            cPanel
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/storage"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            Cloud Storage
                                        </NavLink>
                                    </li>
                                    {/* <li>
                                        <Link
                                            to=""
                                            className={hosting ? "active" : ""}
                                            onClick={() => closeMobileMenu()}
                                        >
                                            Hosting
                                        </Link>
                                        <ul className="submenu">
                                            <li>
                                                <NavLink
                                                    to="/virtual-private-hosting"
                                                    onClick={() => {
                                                        closeMobileMenu();
                                                        setHosting(true);
                                                        setServices(false);
                                                    }}
                                                >
                                                    <BiSolidServer /> Virtual
                                                    Private Server
                                                </NavLink>
                                            </li>
                                            <div class="dropdown-divider"></div>
                                            <li>
                                                <NavLink
                                                    to="/shared-hosting"
                                                    onClick={() => {
                                                        closeMobileMenu();
                                                        setHosting(true);
                                                        setServices(false);
                                                    }}
                                                >
                                                    <BsServer /> Shared Web
                                                    Hosting
                                                </NavLink>
                                            </li>
                                        </ul>
                                    </li> */}
                                    <li>
                                        <Link
                                            to=""
                                            className={services ? "active" : ""}
                                            onClick={() => closeMobileMenu()}
                                        >
                                            Services
                                        </Link>
                                        <ul className="submenu">
                                            <li>
                                                <NavLink
                                                    to="/sslcertificate"
                                                    onClick={() => {
                                                        closeMobileMenu();
                                                        setHosting(false);
                                                        setServices(true);
                                                    }}
                                                >
                                                    <AiFillSafetyCertificate />{" "}
                                                    SSL Certificate
                                                </NavLink>
                                            </li>
                                            <div class="dropdown-divider"></div>
                                            <li>
                                                <NavLink
                                                    to="/managedns"
                                                    onClick={() => {
                                                        closeMobileMenu();
                                                        setHosting(false);
                                                        setServices(true);
                                                    }}
                                                >
                                                    <FaGlobe /> Manage DNS
                                                </NavLink>
                                            </li>
                                            {/* <div class="dropdown-divider"></div> */}
                                            {/* <li>
                                                <NavLink
                                                    to="/storage"
                                                    onClick={() => {
                                                        closeMobileMenu();
                                                        setHosting(false);
                                                        setServices(true);
                                                    }}
                                                >
                                                    <BsServer /> Cloud Storage
                                                </NavLink>
                                            </li> */}
                                        </ul>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/contact"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            Contact
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink
                                            to="/about-us"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(false);
                                            }}
                                        >
                                            About Us
                                        </NavLink>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-4 col-md-4 d-none d-sm-block text-right">
                            <div className="account">
                                <a
                                    href="https://clients.dhakacloud.cloud/index.php?/clientarea/"
                                    target="_blank"
                                    className="theme-btn sign"
                                >
                                    Client Area{" "}
                                    <img
                                        src={require(`../assets/img/sign.png`)}
                                        alt="Client Dahabord"
                                    />
                                </a>
                            </div>
                        </div>
                        <div className="col-md-4 col-sm-4 col-6 d-block d-lg-none">
                            <nav className="navbar navbar-expand-lg navbar-light mobile-nav">
                                <button
                                    className="navbar-toggler"
                                    type="button"
                                    onClick={isMobileMenu}
                                >
                                    <span className="fal fa-bars" />
                                </button>
                            </nav>
                        </div>
                        <div
                            ref={menuRef}
                            className={`collapse navbar-collapse mobile-menu ${
                                mobileMenu ? "show" : ""
                            }`}
                        >
                            <ul className="navbar-nav mr-auto">
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        Home
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/domain"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        Domain
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/virtual-private-hosting"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        VPS
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/shared-hosting"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        cPanel
                                    </NavLink>
                                </li>
                                {/* <li className="nav-item dropdown">
                                    <NavLink
                                        className={`nav-link dropdown-toggle ${
                                            hosting ? "active" : ""
                                        }`}
                                        to="Host"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        // onClick={closeMobileMenu}
                                    >
                                        Hosting
                                    </NavLink>
                                    <div
                                        className="dropdown-menu"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <NavLink
                                            className="dropdown-item"
                                            to="/virtual-private-hosting"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(true);
                                                setServices(false);
                                            }}
                                        >
                                            Virtual Private Server
                                        </NavLink>
                                        <div class="dropdown-divider"></div>
                                        <NavLink
                                            className="dropdown-item"
                                            to="/shared-hosting"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(true);
                                                setServices(false);
                                            }}
                                        >
                                            Shared Web Hosting
                                        </NavLink>
                                    </div>
                                </li> */}
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/storage"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        Cloud Storage
                                    </NavLink>
                                </li>
                                <li className="nav-item dropdown">
                                    <NavLink
                                        className={`nav-link dropdown-toggle ${
                                            services ? "active" : ""
                                        }`}
                                        to="Services"
                                        id="navbarDropdown"
                                        role="button"
                                        data-toggle="dropdown"
                                        // onClick={closeMobileMenu}
                                    >
                                        Services
                                    </NavLink>
                                    <div
                                        className="dropdown-menu"
                                        aria-labelledby="navbarDropdown"
                                    >
                                        <NavLink
                                            className="dropdown-item"
                                            to="/sslcertificate"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(true);
                                            }}
                                        >
                                            SSL Certificate
                                        </NavLink>
                                        <div class="dropdown-divider"></div>
                                        <NavLink
                                            className="dropdown-item"
                                            to="/managedns"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(true);
                                            }}
                                        >
                                            Manage DNS
                                        </NavLink>
                                        {/* <div class="dropdown-divider"></div>
                                        <NavLink
                                            className="dropdown-item"
                                            to="/storage"
                                            onClick={() => {
                                                closeMobileMenu();
                                                setHosting(false);
                                                setServices(true);
                                            }}
                                        >
                                            Cloud Storage
                                        </NavLink> */}
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/contact"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        Contact
                                    </NavLink>
                                </li>
                                <li className="nav-item">
                                    <NavLink
                                        className="nav-link"
                                        to="/about-us"
                                        onClick={() => {
                                            closeMobileMenu();
                                            setHosting(false);
                                            setServices(false);
                                        }}
                                    >
                                        About Us
                                    </NavLink>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

export default Navbar;
