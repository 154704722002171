const ServiceDataStorage = {
    serviceBoxItem: [
        {
            id:1,
            title:"Antivirus",
            text:"Avoid threats by inspecting files using anti-Virus. Make sure all files are scanned properly to ensure the safety.",
            imgUrl:"antivirus.png"
        },
        {
            id:2,
            title:"Encryption",
            text:"Safeguard data through state-of-the-art cryptographic measures. This architecture enables custom setup.",
            imgUrl:"encryption.png"
        },
        {
            id:3,
            title:"Desktop & Mobile",
            text:"Access and sync files and folders from both the desktop & mobile. Work on remote files from anywhere.",
            imgUrl:"desktop.png"
        },
        {
            id:4,
            title:"Ransomware Protection",
            text:"Prevent unrecoverable data loss. Block infected systems from uploading and restore affected data.",
            imgUrl:"ransomware.png"
        },
        {
            id:5,
            title:"Full Text Search",
            text:"Let users access their data in a targeted manner. Shorten their way to the desired file by means of full text search.",
            imgUrl:"textsearch.png"
        },
        {
            id:6,
            title:"True Secure View",
            text:"Let users safely distribute confidential documents efficiently with maximum control and security.",
            imgUrl:"secure.png"
        }
    ],

}


export default ServiceDataStorage
