import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'
import Table from '../TableComponent/Table';


class DS extends Component {

    state = { 
        data: [
            {
                packagename: "Samir",
                vcpu: 5,
                memory: "Test",
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                diskspace: 10,
                bw: 20,
                tpaddress: 128.2,
                price: 500,
                SingleProcessor: 10,
                DualProcessor: 5,
                action: <button>Order Now</button>
            },
            {
                packagename: "Jisan",
                vcpu: 15,
                memory: "Test2",
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                diskspace: 20,
                bw: 20,
                tpaddress: 128.5,
                price: 1500,
                SingleProcessor: 10,
                DualProcessor: 5,
                action: <button>Order Now</button>
            }
        ],

        data1: [
            {
                packagename: "Linux_samir",
                vcpu: 5,
                memory: "Test1",
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                diskspace: 10,
                bw: 20,
                tpaddress: 128.2,
                price: 500,
                SingleProcessor: 10,
                DualProcessor: 5,
                action: <button>Order Now</button>
            },
            {
                packagename: "Linux_jisan",
                vcpu: 15,
                memory: "Test",
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                diskspace: 20,
                bw: 20,
                tpaddress: 128.5,
                price: 1500,
                SingleProcessor: 10,
                DualProcessor: 5,
                action: <button>Order Now</button>
            }
        ]
    }
    columns = [ 
        { label: "Package Name", path: "packagename", content: (item, path) => <td> {item[path]} </td> }, 
        { label: "VCPU", path: "vcpu", content: (item, path) => <td> {item[path]} </td> },
        { label: "Memory", path: "memory", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Memory", path: "memory", content: (item, path) => <td> <img src={item[path]} /></td> },
        { label: "Disk Space", path: "diskspace", content: (item, path) => <td> {item[path]} </td> }, 
        { label: "Bandwidth", path: "bw", content: (item, path) => <td> {item[path]} </td> },
        { label: "IP-Address", path: "tpaddress", content: (item, path) => <td> {item[path]} </td> }, 
        { label: "Price", path: "price", content: (item, path) => <td> {item[path]} </td> }, 
        { label: "Single Processor", path: "SingleProcessor", content: (item, path) => <td> {item[path]} </td> },
        { label: "Dual Processor", path: "DualProcessor", content: (item, path) => <td> {item[path]} </td> },
        { label: "Action", path: "action", content: (item, path) => <td> {item[path]} </td> }
    ];

    
    render() {
        return (
            <section className="services-wrap service-two section-padding-vps pt-0" id="vps">
                <div className="container">

                    <SectionTitle Title="Dedicated Server for Windows" />

                        <div className="row">
                        
                            {/* <h1>WindowsVirtual Private Server</h1> */}
                        </div>
                        <Table 
                            items={this.state.data1}
                            columns={this.columns}
                        />
                    {/* <SectionTitle Title="The latest hosting server technology"  Titlep="VPS. Lorem Ipsum has been the industry's standard dummy text ever since" /> */}
                </div>


                <div className="container">

                    <SectionTitle Title="Dedicated Server for Linux" />

                        <div className="row">
                        
                            {/* <h1>Linux Virtual Private Server</h1> */}
                        </div>
                        {/* <div className="row">
                            <h4>Linux VPS</h4>
                        </div> */}
                        <Table 
                            items={this.state.data1}
                            columns={this.columns}
                        />
                        {/* <Table data={data} columns={columns} /> */}
                    {/* <SectionTitle Title="The latest hosting server technology"  Titlep="VPS. Lorem Ipsum has been the industry's standard dummy text ever since" /> */}
                </div>
            </section>
        )
    }
    }

export default DS
