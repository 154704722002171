import React, { Component } from 'react';
import SectionTitle from './SectionTitle'


const TechPartnerVps = () => {
        return (
            <section className="why-us-wrap section-padding">
                <div className="container">

                <SectionTitle Title="Available Operating Systems" />

                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="brand-cat-a d-md-flex justify-content-lg-between ">
                                {/* <div className="single-brandvps">
                                    <img src={require ("../assets/img/brand/1.png") } alt="klaud" />
                                    <span>vmware</span>
                                </div> */}
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/1.png") } alt="klaud" />
                                    {/* <span>vmware</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/2.png") } alt="klaud" />
                                    {/* <span>veeam</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/3.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/4.png") } alt="klaud" />
                                    {/* <span>resellerclub</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/5.png") } alt="klaud" />
                                    {/* <span>proxmox</span> */}
                                </div>
                            </div>
                            <div className="brand-cat-b d-md-flex justify-content-lg-around">
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/6.png") } alt="klaud" />
                                    {/* <span>cloudflare</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/7.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/8.png") } alt="klaud" />
                                    {/* <span>cPanel</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/9.png") } alt="klaud" />
                                    {/* <span>microsoft</span> */}
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/10.png") } alt="klaud" />
                                </div>
                                {/* <div className="single-brand">
                                    <img src={require ("../assets/img/brand/11.png") } alt="klaud" />
                                    <span>cPanel</span>
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/brand/12.png") } alt="klaud" />
                                    <span>microsoft</span>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
// }

export default TechPartnerVps
