import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'

// class ServiceOneSsl extends Component {
    

//     render() {

//         var ServiceData = this.props.ServiceData;

//         return (
//             <section className="services-wrap section-padding-ssl pt-0" id="service">
//                 <div className="container">
                    
//                 <SectionTitle Title="Our SSL Technology"  Titlep="Powerful, customizable, seamless integration VPS hosting specifically tailored plans are perfect for powerful solution with top performance and flexibility" />  

//                     <div className="row">

//                     {
//                         ServiceData.serviceBoxItem.map(item=> {
//                             return(
//                             <div className="col-xl-3 col-lg-4 col-sm-6 col-12" key={item.id} >
//                                 <div className="single-service-box" data-aos="fade-up" data-aos-duration={800}>
//                                     <div className="service-icon">
//                                         <img src={require (`../../assets/img/services/${item.imgUrl}`)} alt="klaud" />
//                                     </div>
//                                     <h4>{item.title}</h4>
//                                     <p>{item.text}</p>
//                                 </div>
//                             </div>
//                             )
//                         })
//                     }

//                     </div>
//                 </div>
//             </section>
//         )
//     }
// }

// export default ServiceOneSsl


const ServiceOneSsl = ({ ServiceData }) => {
    return (
      <section className="services-wrap section-padding-ssl pt-0" id="service">
        <div className="container">
          <SectionTitle Title="Our SSL Technology" Titlep="Our SSL or Secure Sockets Layer certificates will protect confidential data on your website including passwords, payment data and identity information. Moreover, the certificate lets you place a trust seal on your website increasing the confidence that customers will have transacting on your website." />
  
          <div className="row">
            {ServiceData.serviceBoxItem.map((item) => (
              <div className="col-xl-4 col-lg-4 col-sm-6 col-12" key={item.id}>
                <div className="service-box-2 servicev3" data-aos="fade-up" data-aos-duration={800}>
                  <div className="service-icon">
                    <img src={require(`../../assets/img/services/${item.imgUrl}`)} alt="klaud" />
                  </div>
                  <h4>{item.title}</h4>
                  <p>{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  };
  
  export default ServiceOneSsl;