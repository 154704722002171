import { React, useState, useEffect } from 'react';
import SectionTitle from '../SectionTitle'
import { useFormik } from 'formik';
import axios from 'axios';
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';
import { Container, Form, Row, Col, InputGroup, Button } from 'react-bootstrap';
import { contactUs } from '../schema/Validation';

const ContactThree = () => {

        // const navigate = useNavigate();
        const [isLoading, setIsLoading] = useState(false);
        // const [dropDownValue, setDropDownValue] = useState([]);


        // const fetchDropDownData = async () => {
        //     await axios.get(`https://webapp.race.net.bd/api/adduser`).then(({ data }) => {
        //         console.log(data);
        //         setDropDownValue(data);
        //     })
        // }
    
        // useEffect(() => {
        //     fetchDropDownData();
        // }, []);

        
        const formik = useFormik({
            initialValues: {
                fullname: '',
                lastname: '',
                email: '',
                subject: '',
                message: '',
            },
            validationSchema: contactUs,
    
            onSubmit: (values, { resetForm }) => {
                setIsLoading(true);
                const url = 'https://backend.dhakacloud.cloud/api/emailsend';
                const targetUrl = `${url}?fullname=${values.fullname}&lastname=${values.lastname}&email=${values.email}&phone=${values.subject}&message=${values.message}`;
                axios.post(targetUrl)
            //     axios.post(targetUrl, {
            //      headers : {
            //          "Content-Type": "applicaion/json",
            //          "Access-Control-Allow-Origin": "*",
            //      },
            //  })
                    .then((response) => {
                        if (response.status === 200) {
                            resetForm();
                         Swal.fire({
                            title: "Email Send Successfully",
                              icon: "success",
                           });
                            Swal.fire({
                                position: 'top-end',
                                icon: 'success',
                                title: 'Email Sent Successfully',
                                showConfirmButton: false,
                                timer: 3000,
                                background: '#4b1e85',
                            })
                            setIsLoading(false);
                        }
                    })
                    .catch(({ message }) => {
                        // navigate(`/errors/${message}`);
                        console.log(message);
                    });
            }
        });
        return (
            <section className="contact-us-wrap section-padding" id="contact">
                <div className="contact-shape-wrap">
                    <img src={require("../../assets/img/shape/1.png")} className="shape shape1" alt="" />
                    <img src={require("../../assets/img/shape/2.png")} className="shape shape2" alt="" />
                    <img src={require("../../assets/img/shape/3.png")} className="shape shape3" alt="" />
                    <img src={require("../../assets/img/shape/4.png")} className="shape shape4" alt="" />
                    <img src={require("../../assets/img/shape/5.png")} className="shape shape5" alt="" />
                    <img src={require("../../assets/img/shape/6.png")} className="shape shape6" alt="" />
                    <img src={require("../../assets/img/shape/7.png")} className="shape shape7" alt="" />
                    <img src={require("../../assets/img/shape/8.png")} className="shape shape8" alt="" />
                    <img src={require("../../assets/img/shape/1.png")} className="shape shape9" alt="" />
                    <img src={require("../../assets/img/shape/2.png")} className="shape shape10" alt="" />
                    <img src={require("../../assets/img/shape/3.png")} className="shape shape11" alt="" />
                    <img src={require("../../assets/img/shape/4.png")} className="shape shape12" alt="" />
                    <img src={require("../../assets/img/shape/5.png")} className="shape shape13" alt="" />
                    <img src={require("../../assets/img/shape/6.png")} className="shape shape14" alt="" />
                    <img src={require("../../assets/img/shape/7.png")} className="shape shape15" alt="" />
                    <img src={require("../../assets/img/shape/8.png")} className="shape shape16" alt="" />
                </div>
                <div className="container">
    
                    <SectionTitle Title="Drop Us A Message" />
    
                    {/* <Form className="row" id="contact-form" onSubmit={formik.handleSubmit} > */}
                    <div className="form-wrap">
                        <Form className="row" id="contact-form" onSubmit={formik.handleSubmit} >
                            {/* <form action="mail.php" className="row" id="contact-form" method="POST"> */}
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-input">
                                    <label htmlFor="fname">Full Name </label>
                                    <input type="text" id="fname" placeholder="Full Name"
                                        name="fullname"
                                        onChange={formik.handleChange}
                                        value={formik.values.fullname} />
                                </div>
                                {formik.touched.fullname && formik.errors.fullname ? (
                                    <div className='text-danger'>{formik.errors.fullname}</div>
                                ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-input">
                                    <label htmlFor="lname">Cell Number </label>
                                    <input type="text" id="lastname" placeholder="Cell Number"
                                        name="lastname"
                                        onChange={formik.handleChange}
                                        value={formik.values.lastname} />
                                </div>
                                {formik.touched.lastname && formik.errors.lastname ? (
                                    <div className='text-danger'>{formik.errors.lastname}</div>
                                ) : null}
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                <div className="single-input">
                                    <label htmlFor="email">Your Email </label>
                                    <input type="text" id="email" placeholder="Your mail"
                                        name="email"
                                        onChange={formik.handleChange}
                                        value={formik.values.email} />
                                </div>
                                {formik.touched.email && formik.errors.email ? (
                                    <div className='text-danger'>{formik.errors.email}</div>
                                ) : null}
                                <div className="single-input">
                                    <label htmlFor="subject">Subject</label>
                                    <input type="text" id="subject" placeholder="Subject"
                                        name="subject"
                                        onChange={formik.handleChange}
                                        value={formik.values.subject} />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-6 col-12">
                                {/* <div className="single-input">
                                            <label htmlFor="message">Message</label>
                                            <textarea name="message" id="message" placeholder="message" defaultValue={ ""} />
                                        </div> */}
                                <div className="single-input">
                                    <label htmlFor="message">Message</label>
                                    <textarea name="message"
                                        id="message"
                                        placeholder="Message"
                                        // name="message"
                                        onChange={formik.handleChange}
                                        value={formik.values.message} />
                                </div>
                            </div>
                            <div className="col-12 col-lg-12 text-center mt-40">
                                {isLoading ? <div className="theme-btn">
                                    <span style={{ fontSize: '20px' }}>Sending..</span>
                                    <div className="spinner-border" style={{ width: '20px', height: '20px' }} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div> :
                                    <button type="submit" className="theme-btn">
                                        Submit
                                    </button>}
                            </div>
                        </Form>
                    </div>
                    {/* </Form> */}
                </div>
            </section>
        )
    }

export default ContactThree
