const FooterData = {
    siteInfo: [
        {
            id: 1,
            aboutText: <div>Khawaja Tower (10th Floor) <br />
                Bir Uttam A.K Khandakar Sharak <br />
                95 Mohakhali C/A, Dhaka-1212.</div>,

            image: 'logo1.png'
        }
    ],

    socialIcon: [
        {
            id: 1,
            url: 'https://www.facebook.com/profile.php?id=100094190970077',
            icon: 'fab fa-facebook-f',
        },
        {
            id: 2,
            url: 'https://twitter.com/dhakacloud',
            icon: 'fab fa-twitter',
        },
        // {
        //   id: 3,
        //   url: '.#',
        //   icon: 'fab fa-dribbble',
        // },
        {
            id: 4,
            url: 'https://www.linkedin.com/company/dhakacloud',
            icon: 'fab fa-linkedin-in',
        },
        // {
        //   id: 5,
        //   url: '.#',
        //   icon: 'fab fa-github',
        // }
    ],
    singleWidget: [
        {

            id: 1,
            title: "Domains",
            menuItems: [
                {
                    id: 1,
                    url: "https://clients.dhakacloud.cloud/index.php?/checkdomain/domain/",
                    title: "Domain Name Search"
                },
                {
                    id: 2,
                    url: "https://clients.dhakacloud.cloud/index.php?/checkdomain/domain/",
                    title: "Transfer"
                },
            ]
        },
    ],
    singleWidget1: [
        {
            id: 2,
            title: "Hosting",
            menuItems1: [
                {
                    id: 1,
                    url: "http://dhakacloud.cloud/shared-hosting",
                    title: "Shared Hosting"
                },
                {
                    id: 4,
                    url: "http://dhakacloud.cloud/virtual-private-hosting",
                    title: "VPS Private Server"
                }
            ]
        },
        {
            id: 3,
            title: "Services",
            menuItems1: [
                {
                    id: 1,
                    url: "http://dhakacloud.cloud/sslcertificate",
                    title: "SSL Certificate"
                },
                {
                    id: 1,
                    url: "http://dhakacloud.cloud/managedns",
                    title: "Manage DNS"
                },
                {
                    id: 1,
                    url: "http://dhakacloud.cloud/storage",
                    title: "Cloud Storage"
                },
            ]
        },

        // {
            // id: 4,
            // title: "Support",

            // menuItems1: [
            //     {
            //         id: 1,
            //         url: "https://clients.dhakacloud.cloud/index.php?/tickets/new/",
            //         title: "Support Center"
            //     },
            //     {
            //         id: 3,
            //         url: "http://clients.dhakacloud.cloud/index.php?/tickets/new/",
            //         title: "Submit Ticket"
            //     },
            // ],

            // menuItems1: [
            //     {
            //         id: 1,
            //         url: "https://clients.dhakacloud.cloud/index.php?/tickets/new/",
            //         title: "Support Center"
            //     },
            //     {
            //         id: 3,
            //         url: "http://clients.dhakacloud.cloud/index.php?/tickets/new/",
            //         title: "Submit Ticket"
            //     },
            //     {
            //         id: 4,
            //         url: "",
            //         title: "Live Chat"
            //     },
            //     {
            //         id: 5,
            //         url: "http://dhakacloud.cloud/termsandconditions",
            //         title: "Terms and Conditions"
            //     },
            //     {
            //         id: 6,
            //         url: "http://dhakacloud.cloud/privacypolicy",
            //         title: "Privacy Policy"
            //     },
            //     {
            //         id: 7,
            //         url: "http://dhakacloud.cloud/refundpolicy",
            //         title: "Refund Policy"
            //     },
            // ]
        // }
    ],

    singleWidget2: [

        {
            id: 4,
            title: "Support",
            menuItems2: [
                // {
                //     id: 1,
                //     url: "https://clients.dhakacloud.cloud/index.php?/tickets/new/",
                //     title: "Support Center"
                // },
                // {
                //     id: 3,
                //     url: "http://clients.dhakacloud.cloud/index.php?/tickets/new/",
                //     title: "Submit Ticket"
                // },
                {
                    id: 4,
                    url: "",
                    title: "Live Chat"
                },
                {
                    id: 5,
                    url: "http://dhakacloud.cloud/termsandconditions",
                    title: "Terms and Conditions"
                },
                {
                    id: 6,
                    url: "http://dhakacloud.cloud/privacypolicy",
                    title: "Privacy Policy"
                },
                {
                    id: 7,
                    url: "http://dhakacloud.cloud/refundpolicy",
                    title: "Refund Policy"
                },
            ]
        }
    ],

    paymentSupport: [
        {
            id: 1,
            icon: 'fab fa-cc-paypal',
        },
        {
            id: 2,
            icon: 'fab fa-cc-stripe',
        },
        {
            id: 3,
            icon: 'fab fa-cc-mastercard',
        },
        {
            id: 4,
            icon: 'fab fa-cc-jcb',
        },
        {
            id: 5,
            icon: 'fab fa-cc-amazon-pay',
        },
        {
            id: 6,
            icon: 'fab fa-cc-amex',
        },
        {
            id: 7,
            icon: 'fab fa-cc-visa',
        }
    ],

    copyright: "Copyright © 2023 DhakaCloud Ltd. All rights reserved.",

}


export default FooterData
