const FaqDataManageDns = {
    faqItems: [
        {
            id:1,
            title:"What is Premium DNS?",
            ans:"Compared with a more basic DNS service, Premium DNS ensures faster performance, maximum site availability, and better security. Namecheap PremiumDNS operates from redundant Anycast locations, and works with any domain name from any registrar worldwide.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"Do I need PremiumDNS?",
            ans:"This may depend on the needs of your web projects. If you are running high-traffic websites that have high performance and security requirements, then PremiumDNS would be a sensible option. While on the one hand, websites that are slow or even fail to load can cost businesses dearly, security breaches can be potentially fatal. PremiumDNS is just one measure that can be taken to improve both the security and performance of your website.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"What is the difference between Public DNS and Premium DNS?",
            ans:"Public DNS use recursive name servers that send queries to the DNS, and it is accessible to anyone using the internet. This contrasts with authoritative DNS servers that connect DNS records within a particular zone, and are only available to the domain authority. DhakaCloud PremiumDNS is an authoritative DNS service with benefits that include improved security, speeds, uptime, personalization, as well as guaranteed SLAs. Read more about how free and Premium DNS services match up.",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"Is DhakaCloud DNS fast?",
            ans:"PremiumDNS from Namecheap uses global Anycast servers to ensure the fastest delivery speeds at all times. This also means you can avoid any lost connections or data leaks and enjoy 100% uptime. Learn more about Anycast DNS.",
            iconUrl:"q1.png"
        },
        {
            id:5,
            title:"Does Namecheap have DDoS protection?",
            ans:"Advanced DNS DDoS (Distributed Denial of Service) Protection is just one of the features included when you sign up to PremiumDNS with Namecheap. It uses Anycast DNS which stores DNS records across multiple servers around the world. This helps to redistribute DNS queries across the network at the time of an attack, so denial of service can be avoided. Another way of securing against DDoS attacks is with advanced Content Delivery Network (CDN) services. This is another global network for the content that populates your website. In the time of high traffic or a DDoS attack, it can also redistribute the content accordingly.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataManageDns
