const FaqDataDomain = {
    faqItems: [
        {
            id:1,
            title:"What is a domain name?",
            ans:"A domain name is the address where Internet users can access your website. its a user friendly naming system that allows people to easily navigate web servers and web pages.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"How many domains can I register with you?",
            ans:"We do not have such limitations. You can register as many domains as you want.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"How long does it take to register my domain?",
            ans:"The process of domain registration at DhakaCloud takes a few seconds provided that you have already created an account. However, you will be able to use your domain only in 4-48 hours when the Root DNS data is updated.",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"Do I need to renew my registration?",
            ans:"Yes, you need to renew your domain registration periodically to maintain ownership of your domain name. When you register a domain, you are essentially leasing it for a specific period of time, typically one to ten years, and you have the option to renew your registration before it expires.",
            iconUrl:"q1.png"
        },
        {
            id:5,
            title:"How can I protect my domain from unauthorized transfer?",
            ans:"You can lock your domain. That will prevent it from being moved somewhere. Use the latch icon or the corresponding option in the top or bottom menu (Lock) to lock or unlock your domain on the Domain List.",
            iconUrl:"q1.png"
        },
        {
            id:6,
            title:"How much time does domain transfer procedure take?",
            ans:"It may take from several minutes up to 2 weeks depending on how fast the current owner of the domain replies to the requests.",
            iconUrl:"q1.png"
        },
        {
            id:7,
            title:"How do I get the authorization code to transfer my domain to another registrar?",
            ans:"Click on the domain you want to transfer to another domain, you will see a form with your domain info. Select Basic info tab to get your Auth Code. Clicking on the refresh icon you will set a new authorization code if you wish.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataDomain
