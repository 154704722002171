import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'


// export class PriceOne extends Component {
//     render() {

const PriceSsl = () => {

    return (
        // <section className="price-wrap section-padding-cprice pt-0" id="price">
        <section className="price-wrap section-padding-cprice" id="price">
            <div className="container">

                <SectionTitle Title="SSL Certificates" Titlep="Security, Trust and Credibility for your website." />

                <div className="row">
                    <div className="col-12 col-lg-12">
                        <div className="tab-content">
                            <div id="month" className="tab-pane in active">
                                <div className="row">
                                    <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                        <div className="single-price-table" data-aos="fade-up" data-aos-delay={200} data-aos-duration={800}>
                                            <div className="package-name text-center">
                                                <h3 style={{marginBottom: '5px'}}>POSITIVE SSL</h3>
                                                <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>Validation & Encryption</span>
                                            </div>
                                            <div className="package-features-price">
                                                <div className="price text-center">
                                                    <span>2100 BDT/Annually</span>
                                                </div>
                                                <ul>
                                                    <li><i className="fal fa-check-circle" />Domain Validation</li>
                                                    <li><i className="fal fa-check-circle" />1 Sub-domain</li>
                                                    <li><i className="fal fa-check-circle" />SHA2 & ECC 128/256 bit Encryption</li>
                                                    <li><i className="fal fa-check-circle" />Trust Logo Supported</li>
                                                    <li><i className="fal fa-check-circle" />Issued within 2 Days</li>
                                                    <li><i className="fal fa-check-circle" />Free Reissuance</li>
                                                    <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                </ul>
                                            </div>
                                            <div className="package-btn">
                                                <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=25">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                        <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                            <div className="package-name text-center">
                                                <h3 style={{marginBottom: '5px'}}>COMODO SSL</h3>
                                                <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>Businesses & Enterprise</span>
                                            </div>
                                            <div className="package-features-price">
                                                <div className="price text-center">
                                                    <span>5400 BDT/Annually</span>
                                                </div>
                                                <ul>
                                                    <li><i className="fal fa-check-circle" />Domain Validation</li>
                                                    <li><i className="fal fa-check-circle" />1 Sub-domain</li>
                                                    <li><i className="fal fa-check-circle" />SHA2 & ECC 128/256 bit Encryption</li>
                                                    <li><i className="fal fa-check-circle" />Trust Logo Supported</li>
                                                    <li><i className="fal fa-check-circle" />Issued within 2 Days</li>
                                                    <li><i className="fal fa-check-circle" />Free Reissuance</li>
                                                    <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                </ul>
                                            </div>
                                            <div className="package-btn">
                                                <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=26">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                        <div className="single-price-table" data-aos="fade-up" data-aos-delay={300} data-aos-duration={800}>
                                            <div className="package-name text-center">
                                                <h3 style={{marginBottom: '5px'}}>WILDCARD SSL</h3>
                                                <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>1 SSL for all your subdomains</span>
                                            </div>
                                            <div className="package-features-price">
                                                <div className="price text-center">
                                                    <span>10900 BDT/Annually</span>
                                                </div>
                                                <ul>
                                                    <li><i className="fal fa-check-circle" />Domain Validation</li>
                                                    <li><i className="fal fa-check-circle" />Infinite Sub-domain </li>
                                                    <li><i className="fal fa-check-circle" />SHA2 & ECC 128/256 bit Encryption</li>
                                                    <li><i className="fal fa-check-circle" />Trust Logo Supported</li>
                                                    <li><i className="fal fa-check-circle" />Issued within 2 Days</li>
                                                    <li><i className="fal fa-check-circle" />Free Reissuance</li>
                                                    <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                </ul>
                                            </div>
                                            <div className="package-btn">
                                                <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=27">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-md-6 col-sm-12 col-12">
                                        <div className="single-price-table" data-aos="fade-up" data-aos-delay={400} data-aos-duration={800}>
                                            <div className="package-name text-center">
                                                <h3 style={{marginBottom: '5px'}}>EV SSL</h3>
                                                <span className='freeDomain' style={{color: '#555555',fontSize:'14px'}}>Complete validation for Businesses</span>
                                            </div>
                                            <div className="package-features-price">
                                                <div className="price text-center">
                                                    <span>16300 BDT/Annually</span>
                                                </div>
                                                <ul>
                                                    <li><i className="fal fa-check-circle" />Enterprise Validation</li>
                                                    <li><i className="fal fa-check-circle" />1 Sub-domain</li>
                                                    <li><i className="fal fa-check-circle" />SHA2 & ECC 128/256 bit Encryption</li>
                                                    <li><i className="fal fa-check-circle" />Trust Logo Supported</li>
                                                    <li><i className="fal fa-check-circle" />Issued within 7 Days</li>
                                                    <li><i className="fal fa-check-circle" />Free Reissuance</li>
                                                    <li><i className="fal fa-check-circle" />Unlimited Support</li>
                                                </ul>
                                            </div>
                                            <div className="package-btn">
                                                <a target="_blank" href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=28">Order Now</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default PriceSsl
