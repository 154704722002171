import React, { Component } from 'react'
import Slider from 'react-slick';


const HeroContact = () => {


    const settings = {
        slidesToShow: 1,
        dots: true,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000
    };

    return (
        <section className="hero-slider-wrap" id="/">
            <Slider className="hero-slider-active" >
                <div className="single-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-10 col-12 pr-0">
                                <div className="single-slide-content slide1 text-white">
                                    {/* <span>99% offer this month</span> */}
                                    <h1>Reach Out to DhakaCloud</h1>
            <p>Connect with us today to experience the benefits of our cloud hosting services. We are excited to be your trusted hosting partner, supporting your digital infrastructure and helping you achieve your business goals.</p>                        
	    {/* <a href=".#" className="theme-btn">Get started today</a> */}
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-lg-block col-12">
                                <div className="single-slide-img">
                                    <img src={require('../../assets/img/commoncontact.png')} alt="" />
                                    {/* <img src={require ('../../assets/img/slider1.png')} alt="DhakaCloud" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}

export default HeroContact
