import React, { Component, useEffect } from 'react'
import Slider from 'react-slick';


const PrivacyPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

    
        return (
            <section className="hero-slider-wrap" id="/">
                <div className="hero-slider-active">
                    <div className="single-slide-tandc" style={{height:'auto'}}>
                    <div className='container'>
                    <div className='row'>
                    <div className="col-sm-12 col-md-12 col-xl-12" style={{textAlign:'center',marginBottom:'30px'}}>
                    <h1>Privacy Policy</h1>
                    </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                                    <p className='text-justify'>
                                    This document outlines the privacy policy of Dhakacloud, detailing the procedures and guidelines regarding the collection, utilization, storage, and disclosure of information 
obtained from visitors and customers of our website. Our privacy policy forms an integral part of our contractual agreements, being incorporated into our Terms of Service (TOS) 
and any other contracts established with our users.
                                    </p>
                                    <h4><b>What type of information does collect by Dhakacloud?</b></h4>
                                    <p className='text-justify'>
                                    When individuals visit our website, our web server collects basic user information that includes the domain of your Internet Service Provider (ISP), the time of your visit, and 
the specific pages you access within our website. This information is not personally identifiable, unless necessary for the prevention of fraud or abuse of our system.
<br/>
When customers place an order for our services, we collect the following information:
<br/>
<ul>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
        Traditional contact details: This includes mailing address, phone number, and fax number.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Internet contact information: This refers to email addresses.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Credit card information: We collect the necessary details for processing payments.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Information about the user's computer system: This includes the type of web browser, IP address, and operating system being used.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Non-financial identification information: This pertains to combinations of usernames and passwords.
    </li>

</ul>
            Please note that the collection of this information is required to fulfill orders and provide our services effectively. We handle this data with utmost care and take appropriate 
            measures to safeguard it.
                                    </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Why is this website collecting traditional contact information?</b></h4>
                                    <p className='text-justify'>
                                    We collect traditional contact information for the following purposes:
                                    <br/>
                                    Billing and support: The contact information is necessary to process payments and provide assistance to customers regarding their accounts or services.
Domain registration account establishment: In order to register a domain on behalf of a customer, we require their contact information for administrative and registration 
purposes.
<h4><b>Website administration</b></h4>
The collected contact information helps us manage and maintain the website effectively, ensuring its proper functioning and security.
Customizing the website experience: By having the contact details of users, we can personalize their experience on subsequent visits to our website, tailoring it to their 
preferences and needs.
<br/>
<b>Please note that we handle this contact information in accordance with our privacy policy, ensuring its confidentiality and using it solely for the stated purposes.</b>

                                    </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>How We Use Information</b></h4>
                                    <p className='text-justify'>
                                    We uphold strict confidentiality and do not share, rent, sell, or release your personal information to any individual, entity, or third party.
                                    <br/>
                                    Dhakacloud may utilize the personally identifiable information collected from our website to communicate with users about products and services offered by Dhakacloud, trusted 
affiliates, independent contractors, and business partners. This is done with the aim of enhancing the customer experience with Dhakacloud and its associated entities. 
Additionally, the information may be used for research purposes to assess the effectiveness of our website and the marketing, advertising, and sales efforts of Dhakacloud, 
trusted affiliates, independent contractors, and business partners. For example, we may provide you with information on products that might be of interest or facilitate your 
participation in special partner programs or Dhakacloud promotions. Customer information may also be utilized to deliver system updates or inform customers about new or upgraded 
products.
<br/>
<b>With your permission, we may disclose contact information when gathering and posting testimonials.</b>

<br/>
Customer personal and account information will not be disclosed unless we have valid reasons to believe that such disclosure is necessary to identify, establish contact with, 
or take legal action against an individual who may be causing harm or infringing upon the rights or property of Dhakacloud, our customers, or others. We may also provide 
information to regulatory authorities and law enforcement officials in compliance with applicable laws or if Dhakacloud has a genuine belief, in good faith, that the law requires 
such disclosure.

                                    </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>What if I think that you are not adhering to this statement?</b></h4>
                                    <p className='text-justify'>
                                    If you have any concerns or believe that we are not adhering to our privacy policy in handling your information, we encourage you to contact us. We take privacy seriously and are 
committed to addressing any issues promptly. If, for any reason, we have deviated from our privacy policy, we will rectify the error as soon as we are notified. Your feedback and 
trust are important to us, and we are dedicated to ensuring the protection and proper management of your information.
<br/>
<b>Changes to our Privacy Policy</b>
<br/>
Occasionally, we may revise our Privacy Policy. Any changes or updates will be posted on this page to keep our customers informed about the information we collect, how we use it, 
and the situations in which we may share it with others. By regularly reviewing this page, you can stay up-to-date with our Privacy Policy and any modifications that have been 
made. We are committed to maintaining transparency and ensuring that you are always aware of our privacy practices.

                                    </p>
                        </div>
                        
                    </div>
                </div>
                    </div>
                </div>
                
            </section> 
        )
    }

export default PrivacyPolicy
