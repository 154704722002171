import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'
import Table from '../TableComponent/Table';



class VPS extends Component {

    state = {
        data: [
            {
                packagename: "Cloud VM-1",
                vcpu: 1,
                memory: "1 GB",
                diskspace: "20 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 800 BDT/Monthly",
                price2: "800 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=20" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=20',
                color: 'linear-gradient(140deg, #27b88dd9 0%, #22dd73d4 100%)'
            },
            {
                packagename: "Cloud VM-2",
                vcpu: 2,
                memory: "2 GB",
                diskspace: "40 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 1200 BDT/Monthly",
                price2: "1200 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=45" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=45',
                color: 'linear-gradient(120deg, #a02aafd9 0%, #441687db 100%)'
            },
            {
                packagename: "Cloud VM-3",
                vcpu: 3,
                memory: "4 GB",
                diskspace: "60 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 1800 BDT/Monthly",
                price2: "1800 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=46" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=46',
                color: 'linear-gradient(120deg, #e84769 0%, #9d1d97 100%)'
            },
            {
                packagename: "Cloud VM-4",
                vcpu: 4,
                memory: "6 GB",
                diskspace: "80 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 2200 BDT/Monthly",
                price2: "2200 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=56" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=56',
                color: 'linear-gradient(120deg, #f5592ac9 0%, #f80155d4 100%)'
            },
            {
                packagename: "Cloud VM-5",
                vcpu: 5,
                memory: "7 GB",
                diskspace: "100 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 2500 BDT/Monthly",
                price2: "2500 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=57" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=57',
                color: 'linear-gradient(120deg, #99EC99 0%, #0AAB0D 100%)'
            },
            {
                packagename: "Cloud VM-6",
                vcpu: 6,
                memory: "8 GB",
                diskspace: "120 GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "৳ 3000 BDT/Monthly",
                price2: "3000 BDT/Monthly",
                action: <a href="https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=58" target="_blank"><button>Order Now</button></a>,
                href: 'https://clients.dhakacloud.cloud/?cmd=cart&action=add&id=58',
                color: 'linear-gradient(120deg, #79D9C7 0%, #1177D6 100%)'
            },

        ],

        data1: [
            {
                packagename: "ECONOMY",
                vcpu: 2,
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                memory: "2GB",
                diskspace: "30GB SSD",
                bw: "20 Mbps",
                tpaddress: 1,
                price: "700 BDT",
                action: <button>Order Now</button>
            },
            {
                packagename: "STANDARD",
                vcpu: 4,
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                memory: "4GB",
                diskspace: "60GB SSD",
                bw: "30 Mbps",
                tpaddress: 1,
                price: "3500 BDT",
                action: <button>Order Now</button>
            },
            {
                packagename: "PROFESSIONAL",
                vcpu: 6,
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                memory: "8GB",
                diskspace: "200GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "7500 BDT",
                action: <button>Order Now</button>
            },
            {
                packagename: "ADVANCED",
                vcpu: 8,
                // memory: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg",
                memory: "2GB",
                diskspace: "400GB SSD",
                bw: "Unlimited",
                tpaddress: 1,
                price: "1050 BDT",
                action: <button>Order Now</button>
            }
        ]
    }

    columns = [
        { label: "Package", path: "packagename", content: (item, path) => <td> <span className='rounded-pill text-white py-2 px-3' style={{ background: '#42247f' }}>{item[path]}</span> </td> },
        { label: "vCPU", path: "vcpu", content: (item, path) => <td> {item[path]} </td> },
        { label: "Memory", path: "memory", content: (item, path) => <td> {item[path]} </td> },
        // { label: "Memory", path: "memory", content: (item, path) => <td> <img src={item[path]} /></td> },
        { label: "Disk Space", path: "diskspace", content: (item, path) => <td> {item[path]} </td> },
        { label: "Bandwidth", path: "bw", content: (item, path) => <td> {item[path]} </td> },
        { label: "IP Address", path: "tpaddress", content: (item, path) => <td> {item[path]} </td> },
        { label: "Price", path: "price", content: (item, path) => <td> {item[path]} </td> },
        { label: "Action", path: "action", content: (item, path) => <td> {item[path]} </td> }
    ];


    render() {
        return (
            // <section className="services-wrap service-two section-padding-vps pt-0" id="vps">
            <section className="services-wrap service-two section-padding-vps" id="vps">
                <div className="container">

                    <SectionTitle Title="Deploy a Virtual Private Server" Titlep="KVM based virtualization technology | Fully Automated & Instant Deployments." />

                    <div className="row">

                        {/* <h1>WindowsVirtual Private Server</h1> */}
                    </div>
                    <Table
                        items={this.state.data}
                        columns={this.columns}
                    />


                    <div className="row" id='alternative-for-table'>
                        <div className="col-12 col-lg-12">
                            <div className="tab-content">
                                <div id="month" className="tab-pane in active">

                                    <div className="row"  >
                                        {this.state.data.map((item, index) => {
                                            return (
                                                <div className="col-xl-3 col-md-6 col-sm-12 col-12" key={index}>
                                                    <div className="single-price-table" data-aos="fade-up" data-aos-delay={200} data-aos-duration={800}>
                                                        <div className="package-name text-center">
                                                            <h3>{item.packagename}</h3>
                                                            {/*<span>{item.vcpu}</span>*/}
                                                        </div>
                                                        <div className="package-features-price" style={{ background: `${item.color}` }}>
                                                            <div className="price text-center">
                                                                <span>{item.price2}</span>

                                                            </div>
                                                            <ul>
                                                                <li><i className="fal fa-check-circle" />{item.vcpu}{' vCPU'}</li>
                                                                <li><i className="fal fa-check-circle" />{item.memory}{' RAM'}</li>
                                                                <li><i className="fal fa-check-circle" />{item.diskspace}</li>
                                                                <li><i className="fal fa-check-circle" />{item.bw}{' B/W'}</li>
                                                                <li><i className="fal fa-check-circle" />{item.tpaddress}{' IP Address'}</li>
                                                            </ul>
                                                        </div>
                                                        <div className="package-btn">
                                                            <a target="_blank" href={item.href} style={{ background: `${item.color}` }}>Order Now</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })}

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <SectionTitle Title="The latest hosting server technology"  Titlep="VPS. Lorem Ipsum has been the industry's standard dummy text ever since" /> */}
                </div>


                {/* <div className="container">

                            <SectionTitle Title="Virtual Windows RDP Server" Titlep="KVM based virtualization technology || Fully autometed & Instant deployments cloud server." />

                                <div className="row">
                                
                                </div>
                                <Table 
                                    items={this.state.data1}
                                    columns={this.columns}
                                />
                        </div> */}
            </section>
        )
    }
}

// const VPS = () => {

//     const data = [
//         { id: 1, name: 'samir', roll: 25, imgPath: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg" },
//         { id: 2, name: 'jisan', roll: 30, imgPath: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg" },
//         { id: 3, name: 'race',  roll: 35, imgPath: "https://m.media-amazon.com/images/M/MV5BMDFkYTc0MGEtZmNhMC00ZDIzLWFmNTEtODM1ZmRlYWMwMWFmXkEyXkFqcGdeQXVyMTMxODk2OTU@._V1_UX128_CR0,12,128,176_AL_.jpg" },
//       ];

//       const columns = ['name', 'roll', 'imgPath'];


//     return (
//         <Table data={data} columns={columns} />
//     );
// }





export default VPS
