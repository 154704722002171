const FaqDataSSL = {
    faqItems: [
        {
            id:1,
            title:"Why should I purchase a Comodo SSL Certificate for my domain name?",
            ans:"A SSL Certificate protects your customers and in turn, increases their trust in your business and your website. This is important if your website requires users to log in using passwords or sensitive information like credit card information.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"Does the Comodo SSL Certificate work on all browsers?",
            ans:"Yes, all Comodo SSL Certificates are compatible with all major and popular browsers active today",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"Do I need any technical knowledge to set up a SSL Security Certificate for my domain name?",
            ans:"While it isn't difficult to install a SSL certificate, you need to follow a series of steps. More information on how to install it can be found on our KnowledgeBase or our How to Install SSL Certificate video walkthrough.",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"Is upgrading my Comodo SSL Certificate possible?",
            ans:"No, we don't support any upgrade or downgrade on Comodo SSL Certificate plans at the moment.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataSSL
