import React, { Component, useEffect } from 'react'
import HeroTwo from './Hero/HeroTwo';
import DomainOffer from './Features/DomainOffer';
import DomainData from './Features/DomainData';
import DomainBox from './Features/DomainBox';
import ServiceTwo from './Services/ServiceTwo';
import ServiceData from './Services/ServiceData';
import PriceTwo from './Price/PriceTwo';
import Map from './Map';
import WhyUs from './WhyUs';
import FaqData from './Faq/FaqData';
import FaqTwo from './Faq/FaqTwo';
import Team from './Team';
import TeamData from './Data/TeamData';
import TestimonialData from './Testimonial/TestimonialData';
import Testimonial from './Testimonial/Testimonial';
import ContactOne from './Contact/ContactOne';
import CpanelHosting from './Services/cPanelHosting';
import VPS from './Services/vps';
import DS from './Services/DedicatedServer';
import SSL from './Services/SSLCertificate';
import EmailHosting from './Services/EmailHosting';
import CoLocation from './Services/Co-Location';
import FaqDataEmail from './Faq/FaqDataManageDns';
import PriceThree from './Price/PriceThree';
import PriceOne from './Price/PriceOne';
import PriceFour from './Price/PriceFour';
import ClientEmail from './ClientManageDns';
import HeroTwoCommon from './Hero/HeroTwoCommon';
import HeroManageDns from './Hero/HeroManageDns';
import FaqDataManageDns from './Faq/FaqDataManageDns';
import ClientManageDns from './ClientManageDns';
import ContentBlockWhyDNS from './ContainOfDns';
import WhyOurDNS from './WhyOurDNS';
import ContentBlockDNS from './ContentBlockDNS';

// export class ServicePage extends Component {
//     render() {

const ManageDNSPage = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

        return (
          <React.Fragment>
                <HeroManageDns/>
                {/* <WhyOurDNS/> */}
                <ContentBlockDNS />
                {/* <ContentBlockWhyDNS /> */}
                {/* <ServiceTwo ServiceData={ServiceData} /> */}
                {/* <CpanelHosting /> */}
                {/* <VPS /> */}
                {/* <DS /> */}
                {/* <SSL /> */}
                {/* <EmailHosting /> */}
                {/* <PriceFour /> */}
                {/* <CoLocation /> */}
                {/* <Map/> */}
                {/* <PriceTwo/> */}
                {/* <DomainOffer/> */}
                <FaqTwo FaqData={FaqDataManageDns} />
                {/* <ClientManageDns /> */}
                {/* <Team TeamData={TeamData} />
                <Testimonial TestimonialData={TestimonialData} /> */}
                {/* <ContactOne/> */}
          </React.Fragment>
        )
    }

export default ManageDNSPage
