const FaqDataStorage = {
    faqItems: [
        {
            id:1,
            title:"What is Dhakacloud Ebox?",
            ans:"Ebox serves as a secure file server, providing a means for safe storage, collaboration, and sharing.Storing files in the cloud for easy accessibility across devices and quick sharing has become a widespread practice, with major providers such as Google, Amazon and Microsoft leading the way. However, many of these services involve storing and processing files beyond users' direct control. Ebox, in contrast, is consistently built on open standards and modularity. This makes it a robust and security-focused file platform suitable for both proprietary and open-source software environments. Ebox offers fully sovereign file storage along with modern and efficient productivity tools.By choosing Ebox, users can enjoy the conveniences of public clouds while ensuring compliance and retaining control over their data.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"How secure is Dhakacloud Ebox?",
            ans:"A wide variety of popular operating systems are available including CentOS and Ubuntu.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"Who is Dhakacloud Ebox for?",
            ans:"Ebox employs advanced cryptographic measures to safeguard your data during transmission, while it's stored, and optionally through end-to-end encryption.As an open-source project, all of Ebox's code is transparent and accessible. We are dedicated to constructing secure code, adhering to the highest industry security standards, and conducting routine security audits on our codebase. To further enhance our security posture, we operate a bug bounty program on Hackerone, incentivizing white hat hackers for responsibly disclosing any identified vulnerabilities.",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"How does Dhakacloud Ebox work?",
            ans:"Ebox caters to a broad spectrum of users, spanning individuals, small businesses, organizations of various sizes, multinational corporations, and governmental entities. It is designed for those in pursuit of digital sovereignty and a robust file access platform. Whether you are an individual user or represent a large institution, Ebox provides a versatile solution to meet your diverse digital needs.",
            iconUrl:"q1.png"
        },
        {
            id:5,
            title:"Can I access Dhakacloud Ebox from desktop app or mobile devices?",
            ans:"Yes, Ebox offers both desktop and mobile (Android and iOS) apps. These apps allow you to access, sync, and share your files on the go.",
            iconUrl:"q1.png"
        },
        {
            id:6,
            title:"How does Dhakacloud Ebox work?",
            ans:"Ebox offers a file synchronization and sharing platform where you can conveniently access your files using either the Ebox web interface or dedicated desktop/mobile clients. Security is prioritized, and Ebox employs secure connections to guarantee the safety and integrity of your data throughout the synchronization and sharing processes.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataStorage
