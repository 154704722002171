import React, { Component } from 'react'
import Slider from 'react-slick';


const HeroTermsCondition = () => {


        return (
            <section className="hero-slider-wrap" id="/">
                <div className="hero-slider-active">
                    <div className="single-slide-tandc" style={{height:'auto'}}>
                    <div className='container'>
                    <div className='row'>
                    <div className="col-sm-12 col-md-12 col-xl-12" style={{textAlign:'center'}}>
                    <h1>Terms & Condition</h1>
                    </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Service Contact</b></h4>
                                    <p className='text-justify'>
                                    In the service contract provided by Dhakacloud, the terms "We," "Us," or "Our" refer to Dhakacloud or any party acting on behalf of 
Dhakacloud as instructed. The terms "You," "Your," "Reseller," "Client," or "Customer" refer to the individual or organization 
purchasing the services or any party acting on their behalf. The term "Member" refers to the purchaser of services or any party acting 
on their instructions. Lastly, the term "The Registrant" refers to the person applying for a domain name or any party acting on their 
instructions. In the provided service contract, "The Registry" refers to the specific organization responsible for managing domain 
names. "Server" refers to the computer server equipment used to provide the services. "Web Site" refers to the space allocated by Dhakacloud 
on the server for your use as a site on the Internet. "TOS" refers to the Terms of Service, which encompasses this agreement. Dhakacloud 
retains the right to modify this policy at any time and such modifications will be effective immediately upon being posted on the 
following URL: http://wwwdhakacloud.cloud/. By entering into this agreement, both parties acknowledge and agree to the terms and conditions 
outlined herein. The provisions specified in this document will remain in effect for the duration of the agreement. It is important to note 
that by utilizing any aspect of Dhakacloud's service, you are consenting to be bound by the terms and conditions stated in this service agreement. 
This document serves as the official contract between you (the customer) and Dhakacloud.
                                    </p>
                        </div>

                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Delivery of Service</b></h4>
                                    <p className='text-justify'>
                                    Once your payment is successfully processed, you will promptly receive an automated receipt from our systems. We recommend printing and keeping a
copy of this receipt in a secure place for future reference.

The receipt will contain your unique customer username, which should be used for any inquiries you have for Dhakacloud.

Please be aware that our goal is to deliver all services promptly upon completion of the signup process. However, if we are unable to meet this
timeline, we will notify you through email, telephone, or both.

Payment will only be deducted once the services are successfully delivered to you.

All orders are delivered electronically via email. If you have any questions or concerns regarding your order, please reach out to us
at sales@dhakacloud.cloud.

We are available to assist you 24/7, 365 days a year. Normally, we strive to respond to your inquiries within one hour of receiving them.

The services offered on this website may be subject to export and/or import restrictions. It is important for you to verify the regulations
in your country to determine if these products are permitted for import.

We want to assure you that we do not impose any additional charges for customers using either VISA or Mastercard card schemes. The final price of
the services will remain the same, regardless of the chosen payment method.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Refund Policy</b></h4>
                                    <p className='text-justify'>
                                    If you encounter any dissatisfaction with our service, we encourage you to contact our support department for assistance. Our team will make
every effort to resolve the issue and address your concerns. However, if the problem remains unresolved and you are still dissatisfied, you
have the right to request a refund within a 30-day period. Standard timeline for refund is 7 to 10 working days after the refund request has placed. Refund will be provided using 
the same payment channel using which you have paid. Please note that no refunds will be provided beyond this 30-day period.

Please be aware that refunds are not available for certain items, including .COM, .NET, .ORG domain names, SSL certificates, domain transfer
fees (in or out), or any other products or services obtained through third-party companies affiliated with Dhakacloud.

Refunds are not applicable for VPS or dedicated server services under any circumstances.

Additionally, if we terminate your hosting or other services due to a violation of our Acceptable Use Policy or any other breach of our
Terms and Conditions, no refunds will be issued, unless specified otherwise in those policies. It is important to note that this does not
affect your statutory rights.

Any credit remaining in your account cannot be refunded and must be utilized for future purchases and renewals.

Please note that setup fees are non-refundable. Once paid, these fees cannot be reimbursed.

Accounts that are purchased as part of a promotional offer, whether or not a promo code is used, are not eligible for the 30-day money back guarantee.

If you have sent any funds to Dhakacloud that exceed the amount owed for the services, the excess amount can only be refunded in the form of credit.

It cannot be refunded as a cash or payment refund. The credit can be used for future purchases or renewals.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>SLA Conditions</b></h4>
                                    <p className='text-justify'>
                                    In the event that our service experiences downtime below 99.9% uptime in a given month, you will be eligible for a credit of one free month.
However, it is important to note that you must claim this credit within 7 days of our monitoring system detecting the drop in uptime below
the guaranteed level.

Please be aware that any planned maintenance work is not included in this guarantee and will not be taken into consideration when calculating
uptime. Additionally, dedicated servers currently do not have a Service Level Agreement (SLA) in place.

The uptime level is calculated based on the data provided by our status page and monitoring systems. These sources serve as the official means
of determining uptime for the purpose of this guarantee.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Web Hosting</b></h4>
                                    <p className='text-justify'>
                                    The Terms of Service and Acceptable Use Policies (collectively referred to as "Terms and Policies") are crucial for your association with Dhakacloud.

By utilizing your Dhakacloud account, you hereby agree to abide by all the Terms and Policies outlined in this document.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>General Terms and Policies</b></h4>
                                    <p className='text-justify'>
                                    The Service provided by Dhakacloud is intended for authorized individuals or organizations, referred to as "Subscribers" or "you" in this document.
Your use of the Service is subject to the restrictions outlined below. By using the Service, you agree to comply with all of these Terms and Policies.
If you do not agree to these Terms and Policies, you must cancel your account immediately and refrain from using or attempting to use the Service
thereafter.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Coverage</b></h4>
                                    <p className='text-justify'>
                                    If you are an individual Subscriber, these Terms and Policies apply to all individuals who access the Service through your account.
If you are a commercial Subscriber, these Terms and Policies apply to all your employees, agents, and customers. In both cases, any violation
of these Terms and Policies by anyone using your account will be considered a violation by you.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Prices and Charges</b></h4>
                                    <p className='text-justify'>
                                    Dhakacloud offers the use of cPanel and domain control panels to you free of charge. However, if we incur any charges due to
misuse or abuse on your part, we reserve the right to pass these charges on to you.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Term</b></h4>
                                    <p className='text-justify'>
                                    The Service is provided to you for as long as you desire to use it. However, we reserve the right to terminate the service if you use it in a
manner that violates the terms of use.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Backups</b></h4>
                                    <p className='text-justify'>
                                    Dhakacloud performs daily backups, but these backups are not guaranteed. They are solely for our own use in the event of a server failure.
We recommend that clients regularly backup their data to their personal computers or storage servers. Clients are responsible for their own data.
We may charge a one-time fee of 1000 BDT for backup restoration.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Suspended or Terminated Account Backups</b></h4>
                                    <p className='text-justify'>
                                    If you require a backup for a suspended account, a one-time fee of 2000 BDT is applicable. We retain backups of
terminated accounts for a period of 13 days only. The restoration fee for terminated account backups is 2000 BDT, subject to backup availability. Please
note that we do not provide any guarantee regarding terminated account backups.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12 mt-3">
                        <h3 style={{textAlign:'justify'}}><b>Grounds for Suspension and Termination</b></h3>
                                    <p className='text-justify'>
                                    By using the services provided by Dhakacloud, you acknowledge and accept the obligation to adhere to these Terms and Policies. Failure to comply with
these Terms and Policies, as well as other stated rules, regulations, and policies, may result in the suspension or termination of your account by
Dhakacloud. You acknowledge that Dhakacloud reserves the right to suspend or terminate your account, with or without prior notice, if any of the
following violations occur, whether for the first time or repeatedly-
<ul>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
        Violation of applicable laws, statutes, regulations, court orders, tariffs, or treaties, including intellectual property, communications, privacy,
criminal, and international law.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Hosting websites dedicated to pornography or adult content.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Abusing or violating the privacy or property rights of others, including sending unsolicited bulk email (spamming). You may be required to pay a
cleanup fee if any of Dhakacloud's server IP addresses are blacklisted due to spam from your account.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using your hosting as a file or image hosting service.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Attempting to break or actually breaking the security of any computer network, including the Dhakacloud service itself, or accessing accounts,
messages, or files that do not belong to you.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using your account as a backup service. All files must be part of your website and must be linked to from your account. This restriction does not
apply to dedicated server clients.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Forging or misrepresenting headers, addresses, or other identification in electronic mail or websites, or using methods to disguise the sender's
identity or location. This includes hosting proxy server applications.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Uploading, linking to, or storing pirated software, cracks, or other unauthorized software.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Assisting or directly distributing copyrighted material without authorization.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Excessively using the service in a way that limits the bandwidth available to others.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Providing fake or incomplete contact details during the signup process.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using the service to operate server programs, such as mail servers, IRC servers, game servers, FTP servers, web servers, or streaming audio/video
servers.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using the service for unauthorized relays through any third-party systems.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Attempting to interfere with or deny service to any user or host on the Internet.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Performing a chargeback on any past or present transactions may result in account suspension until resolved. Dhakacloud reserves the right to
remove your account and deny any future business.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using the service for mail bombing, which involves sending multiple messages to a specific destination to disrupt functionality.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Adding or attempting to add addresses to any mailing list without the explicit positive consent of the addressees.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Attempting to cancel, supersede, or interfere with email other than your own.

    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Engaging in harassment through email or website content.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using the service to engage in SYN flood attacks or other denial-of-service attacks.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Using the service to host phishing content.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Providing false data, including fraudulent credit card or payment information, during the signup process.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    No bill credit will be given during a period of suspension. Dhakacloud may retain any amounts you have paid as liquidated damages for your actions
upon termination of your use of the service.

    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    You agree not to access or attempt to access private areas of the service and to notify Dhakacloud immediately of any unauthorized use or breach
of security.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Dhakacloud does not review or examine messages, files, or other materials accessible through the service. Complaints regarding alleged copyright
infringement can be sent to abuse@dhakacloud.cloud.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    If you believe that there is infringing material hosted on Dhakacloud facilities, you are required to provide specific details identifying the
allegedly infringing material and its location(s). Upon receiving a written notice with such information, Dhakacloud will promptly take action to
remove or block access to the material in question. Dhakacloud will also notify the individual who posted the material about the infringement claim.
In the event that the person responsible for the material provides a notification stating that the claim was a mistake or based on misidentification,
Dhakacloud will provide you with a copy of that notification. However, if you fail to initiate appropriate legal action to restrain the alleged
infringement, the challenged material may be restored or made accessible again.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    By using the Service, you acknowledge and accept that you assume all risks associated with it. Dhakacloud and its affiliates are not responsible
for any information, products, or services accessed through the Service, except for those explicitly provided by Dhakacloud. Dhakacloud does not
endorse third-party materials and is not liable for any loss or damage resulting from your use of or reliance on such materials.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    You acknowledge that you are solely responsible for any information or material you post on the Internet, including websites, newsgroups, and
online chat sessions, whether hosted on Dhakacloud equipment or elsewhere. You agree to indemnify and hold Dhakacloud, its employees, and other
customers and subscribers harmless from any claims, losses, costs, liabilities, damages, or expenses arising from your postings.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    You accept responsibility for any damages or loss of service that may occur as a result of your spamming or other violations mentioned in
Section 5 above. These damages encompass various consequences, such as system shut downs, retaliatory attacks, data flooding, and the loss of
peering arrangements. Dhakacloud reserves the right to pursue legal action against you in court to seek compensation for such claims.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    You acknowledge and agree that Dhakacloud shall not have any financial liability to you under any circumstances.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Dhakacloud does not provide any express or implied warranties, including warranties of title, non-infringement, or warranties of merchantability
or fitness for a particular purpose, regarding the Service or any software or other material available through the Service. This applies regardless of
any information or statements you may have provided to Dhakacloud regarding your intended use of the Service. Any oral advice or information provided
by Dhakacloud or its employees shall not create a warranty or modify the terms of this section.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    The Service and any software provided to you by Dhakacloud are provided on an "as is, as available" basis. Dhakacloud does not warrant that the
Service or the software will be error-free or uninterrupted, nor does it guarantee that any defects will be corrected. Dhakacloud also does not provide
a guarantee for the security or backup of any data that you store on the Service.
    </li>
    

</ul>

                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>VPS CPU Usages</b></h4>
                                    <p className='text-justify'>
                                    The client agrees not to run any processes on their VPS that generate a CPU load exceeding 0.9 on the VPS or cause a high CPU load on the host node
for an extended period. Specifically, the client agrees not to utilize the equivalent of a full core for themselves over an extended period.
If disruptive CPU usage occurs, regardless of the time frame, it may lead to a reboot, shutdown, and/or suspension of the VPS.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Content</b></h4>
                                    <p className='text-justify'>
                                    You acknowledge and accept that the Service may provide access to information and content that could be considered controversial, sexually explicit,
or offensive to you or to users under your responsibility. Dhakacloud disclaims any responsibility or control over such materials, and it is your sole
responsibility to use any available screening software or employ other methods to restrict access (particularly by minors) to any material that you
may find objectionable.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Disputes</b></h4>
                                    <p className='text-justify'>
                                    You agree that if any dispute arises between you and Dhakacloud regarding your use of the Service or related to your subscription to and/or use of
the Service, and such dispute cannot be resolved directly between the parties, it will be submitted by the party who feels aggrieved for binding
arbitration. The arbitration will be conducted in accordance with the provisions of Bangladeshi law and International Law.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Governing Law</b></h4>
                                    <p className='text-justify'>
                                    These Terms and Policies are subject to and governed by the laws of Bangladesh and International Laws in all respects.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Cancellation Procedure</b></h4>
                                    <p className='text-justify'>
                                    To request a cancellation, please follow these steps:
<ul>
<li style={{listStyleType:'decimal',marginLeft:'80px'}}>
Log in to your client area.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Locate the package or service you wish to cancel and click the icon next to it.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Click on the "Request Cancellation" option.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    Fill out and submit the cancellation form.
    </li>
    <li style={{listStyleType:'decimal',marginLeft:'80px'}}>
    If you choose an immediate cancellation, your service will be terminated within 24 hours of submitting the form.
    </li>
</ul>


Please note that it is your responsibility to cancel any PayPal subscriptions associated with your account.

Only cancellation requests submitted through the client area will be accepted. No other forms of cancellation will be processed.

Ensure that you submit your cancellation request before the invoice for the current billing cycle is due. Failure to do so will result in the invoice
remaining due and payable. Cancellation requests can only be submitted if there are no outstanding invoices on the account.

For dedicated server cancellations, please submit the request at least 15 days prior to the renewal date to avoid being charged for the next billing
period.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Resellers</b></h4>
                                    <p className='text-justify'>
                                    Resellers are allowed to use their website for personal purposes or to sell services to their own clients. However, it is important to note that
resellers are not permitted to host free accounts under any circumstances.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Transfer</b></h4>
                                    <p className='text-justify'>
                                    Free transfers are only available for cPanel to cPanel transfers. If you require a manual transfer, a one-time charge of $10 will apply.
For Resellers, we offer free transfers of up to 30 accounts. Any additional accounts beyond this limit will be charged at a rate of $1 per account.

Regarding VPS or Dedicated Server transfers, root to root transfers are free and there are no account transfer limits. However, for non-root to
root transfers, the limit is set at 30 accounts. Any additional accounts will be charged at a rate of $1 per account.

For more detailed information, please refer to our transfer policy.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Discount Vouchers</b></h4>
                                    <p className='text-justify'>
                                    Only one voucher can be used per transaction. Multiple vouchers cannot be combined.
If the total order value is lower than the value of the voucher, the remaining amount is forfeited and cannot be carried forward or refunded.
Each customer is limited to using one discount voucher per transaction.
Vouchers can be redeemed for the purchase of any Shared, Reseller, or Dedicated service.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Account Payments</b></h4>
                                    <p className='text-justify'>
                                    
Please be aware of the following actions that will be taken in case of past due invoices for shared, reseller, VPS, and dedicated server accounts:
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Shared/Reseller Accounts</b></h4>
                                    <p className='text-justify'>
                                    1-3 days past due: No action will be taken.<br/>
4 days past due: Accounts will be suspended.<br/>
7 days past due: A late fee will be added to the invoice.<br/>
30 days past due: Accounts will be terminated.<br/>
To reinstate suspended or terminated shared/reseller accounts, a fee of $20.00 will be charged.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>VPS Accounts</b></h4>
                                    <p className='text-justify'>
                                    1-3 days past due: No action will be taken.<br/>
4 days past due: Accounts will be suspended.<br/>
7 days past due: Accounts will be terminated.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Dedicated Server Accounts</b></h4>
                                    <p className='text-justify'>
                                    
Dedicated servers will be suspended as soon as an invoice becomes overdue.
It's important to keep your invoices up to date to avoid any interruptions in your services.
                                    </p>
                        </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">
                        <h4><b>Amendments</b></h4>
                                    <p className='text-justify'>
                                    Dhakacloud reserves the right to amend these Terms and Policies at any time. Any changes will be posted on the terms of use section of the Service.
By continuing to use the Service, you are deemed to consent to the amended Terms and Policies. If you do not agree with the changes and no longer
wish to use the Service, you may terminate your use of the Service by notifying Dhakacloud.
                                    </p>
                        </div>
                    </div>
                </div>
                    </div>
                </div>
                {/* <Slider className="hero-slider-active">
                    <div className="single-slide-tandc">
                        <div className="container">
                            <div className="row">
                                <h1 className='text-center'>Terms & Condition</h1>
                                <div className="col-sm-12 col-md-12 col-xl-12">
                                    <h4><b>Service Contact</b></h4>
                                    <p className='text-justify'>
                                    In the service contract provided by Dhakacloud, the terms "We," "Us," or "Our" refer to Dhakacloud or any party acting on behalf of 
Dhakacloud as instructed. The terms "You," "Your," "Reseller," "Client," or "Customer" refer to the individual or organization 
purchasing the services or any party acting on their behalf. The term "Member" refers to the purchaser of services or any party acting 
on their instructions. Lastly, the term "The Registrant" refers to the person applying for a domain name or any party acting on their 
instructions. In the provided service contract, "The Registry" refers to the specific organization responsible for managing domain 
names. "Server" refers to the computer server equipment used to provide the services. "Web Site" refers to the space allocated by Dhakacloud 
on the server for your use as a site on the Internet. "TOS" refers to the Terms of Service, which encompasses this agreement. Dhakacloud 
retains the right to modify this policy at any time and such modifications will be effective immediately upon being posted on the 
following URL: http://wwwdhakacloud.cloud/. By entering into this agreement, both parties acknowledge and agree to the terms and conditions 
outlined herein. The provisions specified in this document will remain in effect for the duration of the agreement. It is important to note 
that by utilizing any aspect of Dhakacloud's service, you are consenting to be bound by the terms and conditions stated in this service agreement. 
This document serves as the official contract between you (the customer) and Dhakacloud.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider> */}
            </section> 
        )
    }

export default HeroTermsCondition
