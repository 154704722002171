import React, { Component, useEffect } from 'react'
import HeroTwo from './Hero/HeroTwo';
import DomainOffer from './Features/DomainOffer';
import DomainData from './Features/DomainData';
import DomainBox from './Features/DomainBox';
import ServiceTwo from './Services/ServiceTwo';
import ServiceData from './Services/ServiceData';
import PriceThree from './Price/PriceThree';
import Map from './Map';
import WhyUs from './WhyUs';
import FaqData from './Faq/FaqData';
import FaqTwo from './Faq/FaqTwo';
import Team from './Team';
import TeamData from './Data/TeamData';
import TestimonialData from './Testimonial/TestimonialData';
import Testimonial from './Testimonial/Testimonial';
import ContactOne from './Contact/ContactOne';
import CpanelHosting from './Services/cPanelHosting';
import VPS from './Services/vps';
import DS from './Services/DedicatedServer';
import SSL from './Services/SSLCertificate';
import EmailHosting from './Services/EmailHosting';
import CoLocation from './Services/Co-Location';
import ServiceOne from './Services/ServiceOne';
import FaqDataSSL from './Faq/FaqDataSSL';
import HeroTwoCommon from './Hero/HeroTwoCommon';
import HeroSSL from './Hero/HeroSsl';
import ServiceDataSSL from './Services/ServiceDataSSL';
import PriceOne from './Price/PriceOne';
import PriceSsl from './Price/PriceSsl';
import ContentBlock from './ContentBlock';
import ContentBlockSsl from './ContentBlockSsl';
import ServiceOneSsl from './Services/ServiceOneSsl';

// export class ServicePage extends Component {
//     render() {

const SslPage = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

        return (
          <React.Fragment>
                <HeroSSL/>
                <PriceSsl/>
                {/* <ContentBlock/> */}
                {/* <WhyUs/> */}
                {/* <ServiceTwo ServiceData={ServiceData} /> */}
                {/* <ContentBlockSsl /> */}
                 <ServiceOneSsl ServiceData={ServiceDataSSL} />
                 <ContentBlockSsl />
                {/* <CpanelHosting /> */}
                {/* <VPS /> */}
                {/* <DS /> */}
                {/* <SSL /> */}
                {/* <EmailHosting /> */}
                {/* <CoLocation /> */}
                {/* <Map/> */}
                {/* <DomainOffer/> */}
                <FaqTwo FaqData={FaqDataSSL} />
                {/* <Team TeamData={TeamData} />
                <Testimonial TestimonialData={TestimonialData} /> */}
                {/* <ContactOne/> */}
          </React.Fragment>
        )
    }

export default SslPage
