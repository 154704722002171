import React, { Component } from 'react'
import Slider from 'react-slick';


const HeroTwo = () => {


    const settings = {
        slidesToShow: 1,
        dots: true,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 4000
    };



    return (
        <section className="hero-slider-wrap" id="/">
            <Slider className="hero-slider-active" {...settings}>
                <div className="single-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-10 col-12 pr-0">
                                <div className="single-slide-content slide1 text-white">
                                    {/* <span>DhakaCloud</span> */}
                                    <h1>Faster, Secure & Powerful Cloud Hosting Solution Provider.</h1>
                                    <p>Simplicity, Fully autometed, DDoS Protected, Ultra-fast Speed Network, 24/7 Brilliant Support & 99.98% Uptime Guarantee.</p>
                                    <a href="http://dhakacloud.cloud/virtual-private-hosting" className="theme-btn">Get started today</a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-lg-block col-12">
                                <div className="single-slide-img">
                                    <img src={require('../../assets/img/slider1.png')} alt="" />
                                    {/* <img src={require ('../../assets/img/slider1.png')} alt="DhakaCloud" /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-10 col-12 pr-0">
                                <div className="single-slide-content slide2 text-white">
                                    {/* <span>85% offer this month</span> */}
                                    <h1>Secure and Reliable cPanel Hosting for Critical Websites</h1>
                                    <p>Want a reliable, user-friendly hosting solution that's easy to manage and customize? Our cPanel hosting plans offer everything
                                        you need to get your website up and running quickly.</p>
                                    <a href="http://dhakacloud.cloud/shared-hosting" className="theme-btn">Start for Now</a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-lg-block col-12">
                                <div className="single-slide-img">
                                    <img src={require('../../assets/img/slider2.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="single-slide">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-6 col-lg-6 col-md-10 col-12 pr-0">
                                <div className="single-slide-content slide3 text-white">
                                    {/* <span>99% offer this month</span> */}
                                    <h1>Simplify Domain Buying Experience with User-Friendly Platform</h1>
                                    <p>Whether you're launching a new business or looking to revamp your online presence,
                                        our domain selling services make it easy to secure the perfect domain name for your needs.</p>
                                    <a href="http://dhakacloud.cloud/domain" className="theme-btn">Get started today</a>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 d-none d-lg-block col-12">
                                <div className="single-slide-img">
                                    <img src={require('../../assets/img/slider3.png')} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Slider>
        </section>
    )
}

export default HeroTwo
