import React, { Component } from 'react';
import SectionTitle from './SectionTitle'


const TechPartner = () => {
        return (
            <section className="why-us-wrap section-padding">
                <div className="container">

                <SectionTitle Title="Technology Partner worldwide" />

                    <div className="row">
                        <div className="col-12 text-center">
                            <div className="brand-cat-a d-md-flex justify-content-lg-between ">
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/1.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/2.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/3.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/4.png") } alt="klaud" />
                                </div>
                            </div>
                            <div className="brand-cat-b d-md-flex justify-content-lg-around">
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/5.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/6.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/7.png") } alt="klaud" />
                                </div>
                            </div>
                            <div className="brand-cat-b d-md-flex justify-content-lg-around">
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/8.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/9.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/10.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/11.png") } alt="klaud" />
                                </div>
                            </div>
                            <div className="brand-cat-b d-md-flex justify-content-lg-around">
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/12.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/13.png") } alt="klaud" />
                                </div>
                                <div className="single-brand">
                                    <img src={require ("../assets/img/tech/14.png") } alt="klaud" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
// }

export default TechPartner
