const FaqDataCpanel = {
    faqItems: [
        {
            id:1,
            title:"What is Web Hosting?",
            ans:" In simple terms, web hosting involves storing the files and data that make up a website on a server, which is a powerful computer connected to the internet 24/7.",
            iconUrl:"q1.png"
        },
        {
            id:2,
            title:"How does shared hosting work?",
            ans:"Shared hosting works by hosting multiple websites on a single physical server and sharing its resources (RAM, CPU) among all the hosted websites. We are using WHM hosting solution to manage share hosting on dedicated server.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"What is cPanel?",
            ans:"cPanel is a web hosting control panel that provides a graphical user interface (GUI) to manage various aspects of a web hosting environment. It simplifies tasks like website management, email configuration, file management, and database administration.",
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"How do I access cPanel?",
            ans:"To access cPanel, you typically enter your domain name followed by /cpanel in your web browser. For example, yourdomain.com/cpanel. You will then be prompted to enter your cPanel username and password provided by your hosting provider.",
            iconUrl:"q1.png"
        },
        {
            id:5,
            title:"Can I create and manage email accounts in cPanel?",
            ans:"Yes, cPanel allows you to create and manage email accounts associated with your domain. You can set up email addresses, configure email forwarding, and access webmail.",
            iconUrl:"q1.png"
        },
        {
            id:6,
            title:"How do I upload my website files using cPanel?",
            ans:"cPanel provides a File Manager tool that allows you to upload and manage your website files directly from your web browser. Alternatively, you can use FTP (File Transfer Protocol) clients to upload files to your hosting account.",
            iconUrl:"q1.png"
        },
        {
            id:7,
            title:"Can I create and manage databases with cPanel?",
            ans:"Yes, cPanel includes tools like phpMyAdmin that allow you to create and manage MySQL databases easily. You can add, edit, and remove databases and their associated users.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataCpanel
