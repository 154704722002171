import React, { Component, useEffect } from 'react'
import HeroTwo from './Hero/HeroTwo';
import DomainOffer from './Features/DomainOffer';
import DomainData from './Features/DomainData';
import DomainBox from './Features/DomainBox';
import ServiceTwo from './Services/ServiceTwo';
import ServiceData from './Services/ServiceData';
import PriceTwo from './Price/PriceTwo';
import Map from './Map';
import WhyUs from './WhyUs';
import FaqData from './Faq/FaqData';
import FaqTwo from './Faq/FaqTwo';
import Team from './Team';
import TeamData from './Data/TeamData';
import TestimonialData from './Testimonial/TestimonialData';
import Testimonial from './Testimonial/Testimonial';
import ContactOne from './Contact/ContactOne';
import CpanelHosting from './Services/cPanelHosting';
import VPS from './Services/vps';
import DS from './Services/DedicatedServer';
import SSL from './Services/SSLCertificate';
import EmailHosting from './Services/EmailHosting';
import CoLocation from './Services/Co-Location';
import MapContact from './MapContact';
import ContactThree from './Contact/ContactThree';
import HeroTwoCommon from './Hero/HeroTwoCommon';
import HeroContact from './Hero/HeroContact';

    const ContactPage = () => {

        useEffect(() => {
            // 👇️ scroll to top on page load
            window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
          }, []);

        return (
          <React.Fragment>
                <HeroContact/>
                {/* <WhyUs/> */}
                {/* <ServiceTwo ServiceData={ServiceData} /> */}
                {/* <CpanelHosting /> */}
                {/* <VPS /> */}
                {/* <DS /> */}
                {/* <SSL /> */}
                {/* <EmailHosting /> */}
                {/* <CoLocation /> */}
                {/* <MapContact/> */}
                <ContactThree/>
                {/* <PriceTwo/> */}
                {/* <DomainOffer/> */}
                {/* <FaqTwo FaqData={FaqData} /> */}
                {/* {/* <Team TeamData={TeamData} /> */}
                {/* <Testimonial TestimonialData={TestimonialData} /> */}
          </React.Fragment>
        )
    }

export default ContactPage
