const DomainData = {
    domainItem: [
        // {
        //     id:1,
        //     price:"400 BDT",
        //     domain:".dev"
        // },
        // {
        //     id:2,
        //     price:"500 BDT",
        //     domain:".info"
        // },
        // {
        //     id:3,
        //     price:"300 BDT",
        //     domain:".net"
        // },
        // {
        //     id:4,
        //     price:"340 BDT",
        //     domain:".store"
        // },
        // {
        //     id:5,
        //     price:"300 BDT",
        //     domain:".mobi"
        // },
        // {
        //     id:6,
        //     price:"400 BDT",
        //     domain:".biz"
        // }
    ],

}


export default DomainData
