const ServiceData = {
    serviceBoxItem: [
        {
            id:1,
            title:"Control Panel",
            text:"We provide powerfull cPanel control panel with all of our hosting plans.",
            imgUrl:"setup.png"
        },
        {
            id:2,
            title:"Fast Performance",
            text:"Enterprise level SSD storage for high performance and server-level caching.",
            imgUrl:"fast.png"
        },
        {
            id:3,
            title:"Privacy Protected",
            text:"DDoS & firewall protected server. Every cpanel users have firewall control also.",
            imgUrl:"privacy.png"
        },
        {
            id:4,
            title:"Auto Backup",
            text:"We know how important an Website Backup. We take daily, weekly, monthly backup.",
            imgUrl:"backup.png"
        },
        {
            id:5,
            title:"Free SSL Certificate",
            text:"All of our cPanel hosting plan includes Free SSL Certificate for life time free.",
            imgUrl:"ssl.png"
        },
        {
            id:6,
            title:"OneClick Website Installer",
            text:"You can install more then 300+ websites and CMS script using our One Click website installer.",
            imgUrl:"database.png"
        }
    ],

}


export default ServiceData
