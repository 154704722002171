import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'


export class EmailHosting extends Component {
    render() {
        var ServiceData = this.props.ServiceData;

        return (
            <section className="services-wrap service-two section-padding pt-0" id="email">
                <div className="container">

                    <SectionTitle Title="The latest hosting server technology"  Titlep="Email Hosting. Lorem Ipsum has been the industry's standard dummy text ever since" />

                        <div className="row">
                        
                            <h1>Email Hosting</h1>
                        </div>
                        <div className="row">
                            <h4>Email</h4>
                        </div>
                    <SectionTitle Title="The latest hosting server technology"  Titlep="Email Hosting. Lorem Ipsum has been the industry's standard dummy text ever since" />
                </div>
            </section>
        )
    }
}

export default EmailHosting
