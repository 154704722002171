import * as Yup from 'yup';


const contactUs = Yup.object({
  fullname: Yup.string()
    .required('Full Name is required *'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Email address is required *'),
    lastname: Yup.number()
    .typeError('Please type phone number')
    .positive("Please type positive number")
    .integer(" can't include a decimal point")
    .min(8)
    .required('Phone number is required *'),
});



export { contactUs }