import React, { Component, useEffect } from 'react'
import Slider from 'react-slick';


const RefundPolicy = () => {

    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      }, []);

        return (
            <section className="hero-slider-wrap" id="/">
                <div className="hero-slider-active">
                    <div className="single-slide-tandc" style={{height:'auto'}}>
                    <div className='container'>
                    <div className='row'>
                    <div className="col-sm-12 col-md-12 col-xl-12" style={{textAlign:'center',marginBottom:'30px'}}>
                    <h1>Refund Policy</h1>
                    </div>
                        <div className="col-sm-12 col-md-12 col-xl-12">

                                    <p className='text-justify'>
                                    If you encounter any dissatisfaction with our service, we encourage you to contact our support department for assistance. Our team will make every effort to resolve the issue 
and address your concerns. However, if the problem remains unresolved and you are still dissatisfied, you have the right to request a refund within a 30-day period. Standard timeline for refund is 7 to 10 working 
days after the refund request has placed. Refund will be provided using the same payment channel using which you have paid. Please note 
that no refunds will be provided beyond this 30-day period.
<br/><br/>
Please be aware that refunds are not available for certain items, including .COM, .NET, .ORG domain names, SSL certificates, domain transfer fees (in or out), or any other products 
or services obtained through third-party companies affiliated with DhakaCloud. Refunds are not applicable for VPS or dedicated server services under any circumstances. 
<br/><br/>
Additionally, if we terminate your hosting or other services due to a violation of our Acceptable Use Policy or any other breach of our Terms and Conditions, no refunds will be 
issued, unless specified otherwise in those policies. It is important to note that this does not affect your statutory rights. Any credit remaining in your account cannot be 
refunded and must be utilized for future purchases and renewals. Please note that setup fees are non-refundable. Once paid, these fees cannot be reimbursed. Accounts that are 
purchased as part of a promotional offer, whether or not a promo code is used, are not eligible for the 30-day money back guarantee. 
<br/><br/>
If you have sent any funds to Dhakacloud that exceed the amount owed for the services, the excess amount can only be refunded in the form of credit. It cannot be refunded as a 
cash or payment refund. The credit can be used for future purchases or renewals.
                                    </p>
                        </div>

                    </div>
                </div>
                    </div>
                </div>
                
            </section> 
        )
    }

export default RefundPolicy