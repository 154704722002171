import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'


export class SSL extends Component {
    render() {
        var ServiceData = this.props.ServiceData;

        return (
            <section className="services-wrap service-two section-padding pt-0" id="ssl">
                <div className="container">

                    <SectionTitle Title="Comodo SSL Certificates"  Titlep="Security, Trust and Credibility for your website" />

                        <div className="row">
                        
                            <h1>SSL Certificate</h1>
                        </div>
                        <div className="row">
                            <h4>SSL</h4>
                        </div>
                    <SectionTitle Title="The lastest hosting server technology"  Titlep="SSL Certificate. Lorem Ipsum has been the industry's standard dummy text ever since" />
                </div>
            </section>
        )
    }
}

export default SSL
