import React, { Component } from 'react'
import SectionTitle from '../SectionTitle'


export class CoLocation extends Component {
    render() {
        var ServiceData = this.props.ServiceData;

        return (
            <section className="services-wrap service-two section-padding pt-0" id="colocation">
                <div className="container">

                    <SectionTitle Title="The latest hosting server technology"  Titlep="Co-Location Hosting. Lorem Ipsum has been the industry's standard dummy text ever since" />

                        <div className="row">
                        
                            <h1>Co-Location Hosting</h1>
                        </div>
                        <div className="row">
                            <h4>Co-Location</h4>
                        </div>
                    <SectionTitle Title="The latest hosting server technology"  Titlep="Co-Location Hosting. Lorem Ipsum has been the industry's standard dummy text ever since" />
                </div>
            </section>
        )
    }
}

export default CoLocation
