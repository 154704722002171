const FaqData = {
    faqItems: [
        {
            id:1,
            title:"Why should I choose DhakaCloud?",
            ans:"Dhaka Cloud Infrastructure is Fully Managed by Multiple Tier-III Data Centers across the World. Skilled Professionals are Available in 24/7 mode ensuring Service Quality, Reliability and Availability",
	        iconUrl:"q1.png"
        },
        {
            id:2,
            title:"How long does it take to setup my account?",
            ans:"You can setup your account instantly within a minute along with billing facility. If instant setup fails for any reason, our experts will setup within 24 hours.",
            // iconUrl:"fast.png"
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"What payment methods do you accept?",
            ans:"We accept a wide variety of payment methods including bKash, Nagad, Rocket and all Local/International Credit Cards.",
            // iconUrl:"idea.png"
            iconUrl:"q1.png"
        },
        {
            id:4,
            title:"24/7 Friendly Support?",
            ans:"Dhaka Cloud is available in 24/7 model with its skilled, friendly and expert resources over phone, whatsapp, online chat, ticketing system and mail.",
            // iconUrl:"support.png"
            iconUrl:"q1.png"
        },
    ],

}


export default FaqData
