const FaqDataDS = {
    faqItems: [
        {
            id:1,
            title:"What is Dedicated Server Klaud hosting?",
            ans:"Klaud hosting is a hosting service for WordPress sites, which comes with an easy 1-click installation of Demo.",
            iconUrl:"ssd.png"
        },
        {
            id:2,
            title:"Why Choose Khost hosting?",
            ans:"Klaud hosting is a hosting service for WordPress sites, which comes with an easy 1-click installation of Demo.",
            iconUrl:"q1.png"
        },
        {
            id:3,
            title:"Is This an Affiliate Program?",
            ans:"Klaud hosting is a hosting service for WordPress sites, which comes with an easy 1-click installation of Demo.",
            iconUrl:"ssd.png"
        },
        {
            id:4,
            title:"What if I've problems with hosting?",
            ans:"Klaud hosting is a hosting service for WordPress sites, which comes with an easy 1-click installation of Demo.",
            iconUrl:"q1.png"
        },
    ],

}


export default FaqDataDS
