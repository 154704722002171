import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FcHome } from 'react-icons/fc';
import MessengerCustomerChat from 'react-messenger-customer-chat';
class Footer extends Component {

    render() {
        var FooterData = this.props.FooterData;

        return (
            <footer className="footer-wrap footer-two pb-80">
                <div className="footer-top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                                <div className="site-info-widget single-footer-wid">
                                    {
                                        FooterData.siteInfo.map(site => {
                                            return (
                                                <div className="footer-logo" key={site.id}>
                                                    <Link to="/"><img src={require("../assets/img/" + site.image)} alt="klaud" /></Link>
                                                    <p>{site.aboutText}</p>
                                                </div>
                                            )
                                        })
                                    }

                                    <div className="social-pages">
                                        {
                                            FooterData.socialIcon.map(item => {
                                                return (
                                                    <a href={item.url} key={item.id} target="_blank"><i className={item.icon} /></a>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                            {
                                FooterData.singleWidget.map(widget => {
                                    return (
                                        <div className="col-lg-2 col-md-6 col-sm-6 col-12" key={widget.id}>
                                            <div className="single-footer-wid">
                                                <div className="foo-wid-title">
                                                    <h3 key={widget.id}>{widget.title}</h3>
                                                </div>
                                                <ul>
                                                    {
                                                        widget.menuItems.map(item => {
                                                            return (
                                                                <li key={item.id}><Link target="_blank" to={item.url}>{item.title}</Link></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }

                            {
                                FooterData.singleWidget1.map(widget => {
                                    return (
                                        <div className="col-lg-2 col-md-6 col-sm-6 col-12" key={widget.id}>
                                            <div className="single-footer-wid">
                                                <div className="foo-wid-title">
                                                    <h3 key={widget.id}>{widget.title}</h3>
                                                </div>
                                                <ul>

                                                    {
                                                        widget.menuItems1.map(item => {

                                                            return (

                                                                <li key={item.id}><Link to={item.url} >{item.title}</Link></li>
                                                            )

                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                            {
                                FooterData.singleWidget2.map(widget => {
                                    return (
                                        <div className="col-lg-2 col-md-6 col-sm-6 col-12" key={widget.id}>
                                            <div className="single-footer-wid">
                                                <div className="foo-wid-title">
                                                    <h3 key={widget.id}>{widget.title}</h3>
                                                </div>
                                                <ul>
                                                    <li>
                                                        <Link to="https://clients.dhakacloud.cloud/index.php?/tickets/new/" target='blank'>Support Center</Link>
                                                    </li>
                                                    <li>
                                                        <Link to="http://clients.dhakacloud.cloud/index.php?/tickets/new/" target='blank'>Submit Ticket</Link>
                                                    </li>
                                                    {
                                                        widget.menuItems2.map(item => {
                                                            return (
                                                                <li key={item.id}><Link to={item.url} >{item.title}</Link></li>
                                                            )
                                                        })
                                                    }
                                                </ul>
                                            </div>
                                        </div>
                                    )
                                })
                            }


                        </div>
                    </div>
                </div>
                <div className="footer-bottom mt-80">
                    <div className="container">
                        <div className="row align-items-end">
                            <div className="col-md-6 col-12">
                                <div className="copyright-info">
                                    <span>{FooterData.copyright}</span>
                                </div>
                            </div>
                            <div className="col-md-6 col-12 text-md-right">
                                <div className="payment-support">
                                    <ul>
                                        {/* {
                                            FooterData.paymentSupport.map(item =>{
                                                return(
                                                    <li key={item.id}><i className={item.icon} /></li>
                                                )
                                            })
                                        } */}
                                        {/* <img src={require ("../assets/img/brand/9.png") } alt="klaud" /> */}
                                        {/* <img src={require ("../assets/img/alllogo.png") } alt="cloud" /> */}
                                        {/* <img src={require ("../assets/img/alllogo2.png") } alt="cloud" /> */}
                                        {/* <img src={require ("../assets/img/alllogo3.png") } alt="cloud" /> */}
                                        <img src={require("../assets/img/alllogo4.png")} alt="cloud" />
                                        {/* <img src={require ("../assets/img/alllogo1.png") } alt="cloud" /> */}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <MessengerCustomerChat
                    pageId="108043425671338"
                    appId="1672649539875689"
                />
            </footer>
        )
    }
}

export default Footer
